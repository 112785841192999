import {Trans, useTranslation} from "react-i18next";

const keysSection = {
    hint: () => {
        return (
            <>
                <p>
                    <Trans>
                        key-words advice
                    </Trans>
                </p>
            </>
        )
    },
};

const paramSection = {
    hint: () => {
        return (
            <p>
                <Trans>
                    Fill in the table of parameters of your thesis
                </Trans>
            </p>
        )
    },
    pages: () => {
        return (
            <p>
                <Trans>
                    Pages - The number of pages of your thesis excluding the appendix
                </Trans>
            </p>
        )
    },
    tables: () => {
        return (
            <p>
                <Trans>
                    Tables - the number of tables in the main text of the thesis
                </Trans>
            </p>
        )
    },
    figures: () => {
        return (
            <p>
                <Trans>
                    Figures - the number of figures in the main text of the thesis
                </Trans>
            </p>
        )
    },
    appendix: () => {
        return (
            <p>
                <Trans>
                    Appendix - the number of appendix in the main text of the thesis
                </Trans>
            </p>
        )
    },
    literature: () => {
        return (
            <p>
                <Trans>
                    Literature - the number of used literature sources in the thesis
                </Trans>
            </p>
        )
    }
};


const fullName = {
    hint: () => {
        return (<>
            <p>
                Enter the full name of the supervisor and the student.
                These details will be used when generating abstract and confirmation.
            </p>
        </>)
    },
}

const langSection = {
    hint: () => {
        return (
            <p>
                <Trans>
                    Select the language of your work
                </Trans>
            </p>
        )
    },
}

const general = {
    hint: () => {
        return (
            <>
                <p>
                    <Trans>
                        Please fill in all required fields in order to continue
                    </Trans>
                </p>
            </>
        )
    },
}

const ThesisDetailsHints = {
    fullName,
    langSection,
    paramSection,
    keysSection,
    general
}

export default ThesisDetailsHints;
