import styled from "styled-components";

interface NumberInputProps {
    name?: string,
    onChange?: any,
    value?: string,
    maxLength?: number,
    style?: object,
    hasError?: boolean,
    onFocus?: any,
}

const NumberInput = (props: NumberInputProps) => {

    const {onChange, ...other} = props;

    const handleInputChange = (e: any) => {
        const regExp = new RegExp("^$|^(0|[1-9][0-9]*)$");
        const {value} = e.target;
        if (regExp.test(value.toString())) {
            onChange(e);
        }
    }

    return (
        <Input
            onFocusCapture={props.onFocus}
            onChange={handleInputChange}
            {...other}
        />
    )
}

export default NumberInput

const Input = styled.input<{ hasError?: boolean }>`
  border-color: ${props => props.hasError && "red"};
  border-style: solid;
  border-radius: 5px;
  max-width: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  text-align: center;
`
