import React from "react";
import {TextField} from "@mui/material";

interface FilterSelectorProps {
    label: string,
    onChange: any
}

const StudentNumberSelector: React.FC<FilterSelectorProps> = ({label, ...props}) => {

    return (
        <div>
            <TextField
                style={{backgroundColor: "white"}}
                size={"small"}
                id="standard-basic"
                label={label}
                type={"number"}
                onBlur={props.onChange}
            />
        </div>
    )
}

export default StudentNumberSelector
