import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, ButtonGroup, CircularProgress, TextField, Typography} from "@material-ui/core";
import {authenticate} from "../redux/action/AuthActions";
import {useFormik} from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {RootState} from "../redux";
import {useTranslation} from "react-i18next";

import {Trans} from "react-i18next";


const LoginPage = () => {

    const dispatch = useDispatch();

    const {t, i18n} = useTranslation();
    const lng = (lng: string) => i18n.changeLanguage(lng).then(() => console.log("success"))

    const onSignInBtnClick = ({login, password}: any) => {
        dispatch(authenticate(login, password));
    }

    const isLoading = useSelector((state: RootState) => state.auth.isLoading)

    const formik = useFormik({
        onSubmit: onSignInBtnClick,
        validateOnChange: false,
        initialValues: {login: "", password: ""},
        validationSchema: Yup.object().shape({
            login: Yup.string().required("Required filed"),
            password: Yup.string().required("Required filed"),
        }),
    })

    const {errors} = formik;
    const serverError = useSelector((state: RootState) => state.auth.error)

    return (
        <Container>
            <InfoColumn bgColor={"#234771"}>
                <div style={{position: 'absolute', top: 35, left: 30}}>
                    <img src={'./tsi_logo_en_white.svg'} height={55} alt={"logo"}/>
                </div>
                <PortalInfo>
                    <Typography style={{fontWeight: 'bold'}} variant={"h5"}>
                        {t('Graduation works portal')}
                    </Typography>
                    <p>
                        {t('sign-in advice')}
                    </p>
                </PortalInfo>
                <InformationContainer>
                    <DivideLine></DivideLine>    
                    <Typography style={{fontWeight: 'bold', color: '#ffff66', textShadow: '1px 1px 2px #a35b5b52' }} variant={"h6"}>
                        {t('header-attention login-page')}
                    </Typography>
                    <div style={{textAlign: "justify"}}>
                        <p>
                        {t('first-paragraph login-page')}
                        </p>
                        <RedirectContainer>
                            <a href="https://e.tsi.lv/course/view.php?id=2503" style={{color: 'inherit'}}>
                                <p>{t('redirect-text login-page')}</p>
                            </a>
                        </RedirectContainer>
                        <p>{t('second-paragrapgh login-page')}</p>
                        <p>{t('list-header login-page')}</p>
                        <ul>
                            <li>{t('list-element-1 login-page')}</li>  
                            <li>{t('list-element-2 login-page')}</li>  
                        </ul>
                        <div>
                            {t('third-paragraph login-page')}
                        </div>
                    </div>
                </InformationContainer>
            </InfoColumn>

            <Column>
                <LanguageContainer>
                    <ButtonGroup size={"small"} variant="text">
                        <Button size={'small'} onClick={() => lng("ru")}>RU</Button>
                        <Button size={'small'} onClick={() => lng("lv")}>LV</Button>
                        <Button size={'small'} onClick={() => lng("en")}>EN</Button>
                    </ButtonGroup>
                </LanguageContainer>
                <FormWrapper>
                    <LoadingContainer>
                        {isLoading && <CircularProgress size={32}/>}
                    </LoadingContainer>
                    <form onSubmit={formik.handleSubmit}>
                        <Typography
                            align={"left"}
                            color={"primary"}
                            variant={"h5"}
                            paragraph
                        >
                            {t('sign-in')}
                        </Typography>
                        <TextField
                            id="login" label={t('Login')} fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.login}
                            error={errors.login !== undefined}
                            helperText={errors.login}
                            onBlur={() => formik.validateField("login")}
                            disabled={isLoading}
                            autoComplete = "on"
                        />
                        <TextField
                            id="password" type="password" fullWidth
                            label={t('Password')} margin={'normal'}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            onBlur={() => formik.validateField("password")}
                            error={errors.password !== undefined}
                            helperText={errors.password}
                            disabled={isLoading}
                            autoComplete = "on"
                        />
                        <ErrorContainer>
                            {serverError && serverError}
                        </ErrorContainer>
                        <ButtonContainer>
                            <Button
                                disabled={isLoading} type={"submit"}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                {t('Enter')}
                            </Button>
                        </ButtonContainer>
                    </form>
                </FormWrapper>
            </Column>

        </Container>
    )
}

export default LoginPage;

const ErrorContainer = styled.div`
  font-size: 14px;
  color: red;
`

const LanguageContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`


const LoadingContainer = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
`

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  width: 800px;
  height: 600px;
  background-color: #FFF;
  border-radius: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
`;

const Column = styled.div <{ bgColor?: string }>`
  background-color: ${props => props.bgColor || "#FFF"};
  align-items: center;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  flex: 1;
`;

const InfoColumn = styled(Column)`
  flex-direction: column;
  position: relative;
  justify-content: center;
  box-shadow: 8px 0 10px -2px #888;
  z-index: 100;
`

const FormWrapper = styled.div`{
  position: relative;
  max-width: 300px;
  padding-bottom: 64px;
}`

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
`


const InformationContainer = styled.div`
    color: #ffff66;
    text-shadow: 1px 1px 2px #a35b5b52;
    font-weight: 400;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.25;
    font-size: 12px;
`
const DivideLine = styled.div`
    
    border-top-style: solid;
    border-top-width: thin;
    border-color: white;
    padding-bottom: 10px;
`

const RedirectContainer = styled.div`
    
    text-align: center;
    font-weight: bold;
    font-size: 12px;
`

const PortalInfo = styled.div`
    
    text-align: center;
    color: #FFF;
    padding: 80px 30px 0px;
    line-height: 1.5;
`