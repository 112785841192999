import IThesisSpecs from "../../../dto/IThesisSpecs";
import IThesisFilesState from "../../../dto/IThesisFilesState";

const formInitValues: IThesisSpecs & IThesisFilesState = {
    studentNameRu: "",
    supervisorNameRu: "",
    confirmed: false,
    confirmation: false,
    publicationConfirmCancel: false,
    isPublicationConfirmChoose: false,
    publicationConfirm: false,
    finalDocumentName: "",
    supportDocumentName: "",
    thesisMainDocumentName: "",
    abstractEn: "",
    abstractLv: "",
    appendixCount: 0,
    imageCount: 0,
    keyWordsEn: [],
    keyWordsLv: [],
    languageId: 0,
    pageCount: 0,
    referenceCount: 0,
    studentNameEn: "",
    studentNameLv: "",
    supervisorNameEn: "",
    supervisorNameLv: "",
    tableCount: 0,
    thesisId: 0
}

export default formInitValues

