import IThesisCard from "../../dto/IThesisCard";
import ThesisSpecs from "../../dto/IThesisSpecs";

export const WORKS_FETCHING = "WORKS_FETCHING";
export const WORK_FETCH_SUCCESS = "WORK_FETCH_SUCCESS ";
export const WORK_FETCH_FAIL = "WORK_FETCH_FAIL";

export const THESIS_SPECS_FETCHING = "THESIS_SPECS_FETCHING";
export const THESIS_SPECS_SUCCESS = "THESIS_SPECS_FETCH_SUCCESS ";
export const THESIS_SPECS_FAIL = "THESIS_SPECS_FETCH_FAIL";

export const THESIS_FILE_UPLOADING = "THESIS_FILE_UPLOADING";
export const THESIS_FILE_UPLOADING_SUCCESS = "THESIS_FILE_UPLOADING_SUCCESS ";
export const THESIS_FILE_UPLOADING_FAIL = "THESIS_FILE_UPLOADING_FAIL";

interface ThesisUploading {
    type: typeof THESIS_FILE_UPLOADING,
}

interface ThesisUploadingSuccess {
    type: typeof THESIS_FILE_UPLOADING_SUCCESS,
}

interface ThesisUploadingFail {
    type: typeof THESIS_FILE_UPLOADING_FAIL,
    payload: string,
}

interface WorksFetch {
    type: typeof WORKS_FETCHING,
}

interface WorksFetchSuccess {
    type: typeof WORK_FETCH_SUCCESS,
    payload: IThesisCard[],
}

interface WorksFetchFail {
    type: typeof WORK_FETCH_FAIL,
    payload: string
}

interface ThesisSpecsFetch {
    type: typeof THESIS_SPECS_FETCHING,
}

interface ThesisSpecsFetchSuccess {
    type: typeof THESIS_SPECS_SUCCESS,
    payload: ThesisSpecs,
}

interface ThesisSpecsFetchFail {
    type: typeof THESIS_SPECS_FAIL,
    payload: string
}


export type WorkActionTypes =
    WorksFetch | WorksFetchSuccess | WorksFetchFail |
    ThesisSpecsFetch | ThesisSpecsFetchSuccess | ThesisSpecsFetchFail |
    ThesisUploading | ThesisUploadingSuccess | ThesisUploadingFail
