import styled from "styled-components";
import {Button, IconButton, makeStyles, Step, StepLabel, Stepper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import FormStep, {StepProps} from "./form-steps/Step";
import {useFormik} from "formik";
import formInitValues from "../WorkUploadPage/Model/FormInitValues";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import IThesisSpecs from "../../dto/IThesisSpecs";
import {useTranslation} from "react-i18next";
import thesisApi from "../../api/thesisApi";

import {logout} from "../../redux/action/AuthActions";
import {useDispatch} from "react-redux";
import { Close } from "@material-ui/icons";


interface FormStepperProps {
    steps: FormStep[];
    onStepChange: Function,
}

const FormStepper = ({steps, onStepChange}: FormStepperProps) => {
    const dispatch = useDispatch();

    const history = useHistory();
    const [stepIndex, setStepIndex] = useState<number>(0)
    const {t, i18n} = useTranslation();

    const [progressLabel, setProgressLabel] = useState<string>("")
    const onLoadingStateChange = (label: string) => setProgressLabel(label);

    const ActiveForm: React.FC<StepProps> = steps[stepIndex].getForm();
    const formValidationSchema = steps[stepIndex].formValidationSchema;

    useEffect(() => {
        onStepChange(stepIndex)
    }, [stepIndex])

    const formik = useFormik({
        onSubmit: () => console.log("Submit"),
        validationSchema: formValidationSchema,
        initialValues: formInitValues,
        validateOnChange: false,
    });

    const onNextButtonClick = async () => {
        const error = await formik.validateForm()
        if (_.isEmpty(error)) setStepIndex(stepIndex + 1);
        console.log(error)
    }

    const onConfirmButtonClick = async () => {
        const thesisSpecsForm: IThesisSpecs = formik.values;
        thesisApi.saveThesisSpecs(thesisSpecsForm.thesisId, thesisSpecsForm)
            .then(() => history.replace("/student/works/"))
            .catch(err => console.log(err))
    }

    const onBackButtonClick = async () => {
        formik.setFieldValue("confirmed", false)
        stepIndex === 0 ? history.goBack() : setStepIndex(stepIndex - 1)
    }

    const nextButton = () => {
        return (stepIndex === steps.length - 1) ?
            <Button
                onClick={onConfirmButtonClick}
                disabled={!_.isEmpty(progressLabel) || !formik.values.confirmation}
                variant="contained"
                color="secondary"
                size="small">
                {t('Confirm and finish')}
            </Button> :
            <Button
                onClick={onNextButtonClick}
                disabled={!_.isEmpty(progressLabel)}
                variant="contained"
                color="primary"
                size="small">
                {t('Next')}
            </Button>
    }


    return (
        <FormContainer>
            <FormHeader>
            {/*logOut button  */}
            <div style={{display: "flex", justifyContent: "flex-end", paddingBottom:"20px"}}>
                <div>
                     <Button 
                        onClick={() => dispatch(logout())} 
                        size={"small"} 
                        color={"primary"}
                        variant="text"
                    >
                        <u>
                            Logout
                        </u>
                        
                    </Button> 

                </div>
            </div> 
            {/* -------- */}
            </FormHeader>
            <FormContent>
                {progressLabel &&
                <StateContainer>
                    {progressLabel}
                </StateContainer>}
                <ActiveForm
                    formik={formik}
                    setProgressLabel={onLoadingStateChange}
                />
            </FormContent>

            <FormFooter>
                {nextButton()}
                <div style={{width: 25}}/>
                <Button
                    onClick={onBackButtonClick}
                    disabled={!_.isEmpty(progressLabel)}
                    variant="contained"
                    color="default"
                    size={'small'}>
                    {t('Back')}
                </Button>
            </FormFooter>
        </FormContainer>
    )

}

export default FormStepper;

const StateContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFF;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
`;

const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
`;

const FormFooter = styled.div`
  // border-top-style: solid;
  border-top: gray;
  border-top-width: 1px;
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;
`;

const FormContent = styled.div`
  flex: 1;
  position: relative;
  flex-direction: row;
  // overflow-y: scroll;
`;

const FormHeader = styled.div``;
