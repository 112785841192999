import styled from "styled-components";
import React, {useState} from "react";
import {Button, IconButton, makeStyles, Step, StepLabel, Stepper, Typography} from "@material-ui/core";
import FormStepper from "./FormStepper";
import ThesisDetails from "./form-steps/ThesisDetails";
import Abstract from "./form-steps/AbstractStep";
import Uploading from "./form-steps/Uploading";
import Confirmation from "./form-steps/Confirmation";
import Check from "./form-steps/Check";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import DescriptionFormSchema from "./validation/ThesisSpecsDetails";
import AbstractFormSchema from "./validation/AbstractForm";
import UploadingFormSchema from "./validation/Uploading";
import ConfirmationFromSchema from "./validation/Confirmation";
import {useTranslation} from "react-i18next";


const ThesisUploadingPage = () => {

    const styles = useStyles();
    const {t, i18n} = useTranslation();

    const thesisUploadingAdviceBar = useSelector(
        (state: RootState) => state.thesisUploadingAdviceBar
    )

    const [step, setStep] = useState<number>(0);

    const steps = [
        new ThesisDetails("thesis-details", t('Details'), DescriptionFormSchema),
        new Abstract("abstracts", t('Abstracts'), AbstractFormSchema),
        new Uploading("thesis-uploading", t('Uploading'), UploadingFormSchema),
        new Confirmation("confirmation", t('Confirmation'), ConfirmationFromSchema),
        new Check("check", t('Check'), undefined)
    ]

    return (
        <Card>
            <InfoColumn bgColor={"#234771"}>
                <LabelContainer>
                    <img src={'/tsi_logo_en_white.svg'}
                         height={65}
                         alt={"logo"}
                    />
                </LabelContainer>
                <HintContainer>
                    {thesisUploadingAdviceBar.advice}
                </HintContainer>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Stepper className={styles.root} orientation={"vertical"} activeStep={step}>
                        {steps.map(step => (
                            <Step style={{marginBottom: 10}} key={step.id}>
                                <StepLabel>
                                    {step.stepLabel}
                                </StepLabel>
                            </Step>)
                        )}
                    </Stepper>
                </div>
                <FooterContainer>
                </FooterContainer>
            </InfoColumn>
            <MainColumn>
                <FormStepper
                    steps={steps}
                    onStepChange={(step: number) => setStep(step)}
                />
            </MainColumn>
        </Card>
    )
}

export default ThesisUploadingPage;


const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'left',
        backgroundColor: "rgba(35,71,113,0)",
        "& .MuiStepIcon-active": {
            color: "#234771",
            borderWidth: "thin",
            borderStyle: "solid",
            borderColor: "white",
            borderRadius: 100
        },
        "& .MuiStepIcon-active text": {fill: "white", fontWeight: "bold"},
        "& .MuiStepIcon-completed": {color: "white"},
        "& .Mui-disabled .MuiStepIcon-root": {color: "white"},
        "& .Mui-disabled .MuiStepIcon-root text": {fill: "#234771", fontWeight: "bold"},
        "& .Mui-disabled .MuiStepIcon-root span": {color: "#234771"},
        "& .MuiStepLabel-labelContainer span": {fontSize: 16, fontWeight: "bold", color: "white"},
        "& .MuiStepLabel-labelContainer": {fontSize: 16, color: "white"},
    },
}));

const Card = styled.div`
  display: flex;
  background-color: #FFF;
  border-radius: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
`;

const MainColumn = styled.div<{ bgColor?: string }>`
  background-color: ${props => props.bgColor || "#FFF"};
  align-items: stretch;
  display: flex;
  border-radius: 15px;
  justify-content: left;
  padding: 35px;
  width: 540px;
`

const InfoColumn = styled(MainColumn)`
  flex-direction: column;
  position: relative;
  box-shadow: 8px 0 10px -2px #888;
  padding-top: 140px;
  padding-bottom: 15px;
  z-index: 100;
  width: 320px;
  overflow-y: hidden;
`

const LabelContainer = styled.div`
  top: 45px;
  left: 40px;
  color: white;
  position: absolute;
`

const HintContainer = styled.div`
  display: flex;
  align-items: center; /* align vertical */
  flex-direction: row;
  min-height: 160px;
  color: #FFF;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 10px;
  border-color: white;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-top-style: solid;
  border-top-width: thin;
  text-align: justify;
  line-height: 1.5;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  min-height: 30px;
  color: white;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 10px;
  font-weight: bold;
`
