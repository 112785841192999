import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Tooltip, Typography
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, {forwardRef, useImperativeHandle} from "react";
import styled from "styled-components";
import {cutString} from "../../utils/StringUtils";
import DeleteIcon from '@material-ui/icons/Delete';
import {useTranslation} from "react-i18next";
import { forEach } from "lodash";

const renderHeaderRow = (labels: string[], t: any, onAddBtnClick: any) => {

    const renderHeaderCells = labels.map((label: string, index: number) => (
        <TableCell key={index} align="center">
            {label}
        </TableCell>)
    )

    return (
        <TableRow>
            {renderHeaderCells}
            <TableCell align="center">
                {t('Action')}
            </TableCell>
        </TableRow>
    )
}

const renderFakeRow = (labels: String[], onAddBtnPress: any) => {
    const renderFakeCells = labels.map((value, index, array) => (
        <TableCell onClick={onAddBtnPress} key={index} align="center">
            <FakeInput/>
        </TableCell>)
    )

    return (
        <TableRow key={"fake-row"}>
            {renderFakeCells}
            <TableCell align="center">
                <IconButton onClick={onAddBtnPress} style={{padding: 2}}>
                    <AddCircleIcon fontSize={"small"}/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

const KeyWordAddDialog = forwardRef((props: any, ref) => {

    const {labels, onKeyWordAdd} = props;
    const keyWordsAmount = new Array(labels.length).fill("");
    const [isDialogOpen, setDialogOpen] = React.useState(false);
    const [keywords, setKeywords] = React.useState(keyWordsAmount);


    useImperativeHandle(ref, () => ({
        setPopupState(isOpen: boolean) {
            setDialogOpen(isOpen);
        }
    }));

    const onKeyWordChange = (e: any, index: number) => {
        const userInput = (
            e.target.value.length >= 1
            &&
            !e.target.value.trim()
            )?
                e.target.value.trim():
                e.target.value;
        //const userInput = e.target.value;
        const updatedKeywords = [...keywords];
        updatedKeywords[index] = userInput;
        setKeywords(updatedKeywords);
    }

    const onAddBtnClick = () => {
        const updatedKeywords = [...keywords];
        updatedKeywords.forEach((keyword, index) => {
            updatedKeywords[index] = keyword.trim();
        });
        setKeywords(new Array(labels.length).fill(""))
        onKeyWordAdd([...updatedKeywords]);
        setDialogOpen(false);
    }

    const inputs = labels.map((label: string, index: number) => {
        return (
            <TextField
                value={keywords[index]}
                onChange={(e) => onKeyWordChange(e, index)}
                key={"key-input-" + label}
                style={{width: 130}}
                size={"small"}
                label={label}
            />
        )
    })

    const checkIfDisabled = () => {
        return keywords.includes('');
    }

    return (
        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle id="add-key-dialog-title">
                {"New Keyword "}
            </DialogTitle>
            <DialogContent>
                <KeywordsInputContainer>
                    {inputs}
                </KeywordsInputContainer>
            </DialogContent>
            <DialogActions>
                <Button size={"small"} onClick={() => setDialogOpen(false)}> Cancel </Button>
                <Button autoFocus size={"small"} onClick={onAddBtnClick} disabled={ checkIfDisabled() }> Add </Button>
            </DialogActions>
        </Dialog>
    )
})

const KeysTable = (props: React.PropsWithChildren<any>) => {

    const {t, i18n} = useTranslation();

    const {max = 5, rows = [], labels = [], onKeyWordAdd, onKeyWordRemove: onRemoveButtonClick, secondText} = props;
    const keysCount = rows ? rows[0].length : 0;


    const ref: any = React.createRef();


    const renderTable = () => {
        const tableRows = [];
        for (let i = 0; i < keysCount; i++) {
            const firstRow: String[] = rows.map((keys: String[]) => keys[i])
            const renderCells = firstRow.map((value, index) => (
                <TableCell key={value + index.toString()} size={"small"} align="center">
                    <Tooltip title={value} arrow>
                        <Typography variant={"subtitle2"}>
                            {cutString(value, 16)}
                        </Typography>
                    </Tooltip>
                </TableCell>))

            tableRows.push(
                <TableRow key={"content-row-" + i}>
                    {renderCells}
                    <TableCell padding={"none"} align="center">
                        <IconButton onClick={() => onRemoveButtonClick(i)} style={{padding: 2}}>
                            <DeleteIcon fontSize={"small"}/>
                        </IconButton>
                    </TableCell>
                </TableRow>)
        }
        return tableRows;
    }

    const onAddBtnPress = () => ref.current.setPopupState(true);

    const isAddButtonDisabled = max === keysCount;

    return (
        <>
            <KeyWordAddDialog labels={labels} onKeyWordAdd={onKeyWordAdd} ref={ref}/>
            <TableContainer>
                <Table stickyHeader size="small" aria-label="key-words">
                    <caption style={{padding: 10, margin: 0}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{color: "red",}}>{secondText}</div>
                            <div>{t('Limit')} {keysCount}/{max}</div>
                        </div>
                    </caption>
                    <TableHead>
                        {renderHeaderRow(labels, t, onAddBtnPress)}
                    </TableHead>
                    <TableBody>
                        {renderTable()}
                        {!isAddButtonDisabled && renderFakeRow(labels, onAddBtnPress)}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default KeysTable

const FakeInput = styled.div`
  background-color: #e2e2e2;
  height: 18px;
`

const KeywordsInputContainer = styled.div`
  width: 380px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
