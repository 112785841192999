import Step, {StepProps} from "./Step";
import React, {useEffect} from "react";
import styled from "styled-components";
import ThesisApi from "../../../api/thesisApi";
import IThesisSpecs from "../../../dto/IThesisSpecs";
import FileCard from "../../../component/FileCard";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import IThesisCard from "../../../dto/IThesisCard";
import {fetchFinalDocument, fetchFinalDocumentById} from "../../../api/thesisFilesApi";
import {setWorkUpFormHint} from "../../../redux/action/ThesisUploadingFromActions";
import checkAdvice from "../advice/checkAdvice";

import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const {assembleFinalDocument} = ThesisApi;


class Check extends Step {
    getForm = (): React.FC<StepProps> => CheckFormStep
}

export default Check;

const CheckFormStep = ({formik, setProgressLabel}: StepProps): JSX.Element => {
    const dispatch = useDispatch();
    const {thesisId}: IThesisSpecs = formik.values;

    const {errors} = formik;
    const {t, i18n} = useTranslation();
    const checkForm: IThesisSpecs = formik.values;
    const thesisSpecs: IThesisSpecs = formik.values;


    const {theses} = useSelector((state: RootState) => state.thesisList);
    const uploadingThesis: IThesisCard[] = theses.filter(t => t.id === thesisId);


    useEffect(() => {
        formik.setFieldValue("confirmation", false)
    }, [thesisSpecs.thesisId])

    useEffect(() => {
        dispatch(setWorkUpFormHint(checkAdvice.get()))
    }, [dispatch])

    useEffect(() => {
        assembleFinalDocument(thesisId).catch(err => console.log(err))
        formik.setFieldValue("confirmed", true)
    }, [thesisId])

    return (
        <Container>
            <FileCard
                thesis={uploadingThesis[0]}
                onDownloadButtonClick={({id}: IThesisCard) => fetchFinalDocumentById(id)}
            />

            <Typography style={{color: 'darkred', fontWeight: 'bold'}} variant={"subtitle1"}>
                {t("check-step attention")}
            </Typography>

            <Typography style={{color: 'darkred', fontWeight: 'bold'}} align={"left"} variant={"subtitle2"}>
                {t("check-step should checked")}
            </Typography>


            
            <Typography style={{color: 'black', paddingBottom: "20px"}} align={"left"} variant={"subtitle2"}>
                
                
                <ul>
                    <li> {t("check-step list-element 1")}</li>  
                    <li> {t("check-step list-element 2")}</li>  
                    <li> {t("check-step list-element 3")}</li>  
                    <li> {t("check-step list-element 4")}</li>  
                    <li> {t("check-step list-element 5")}</li>  
                    <li> {t("check-step list-element 6")}</li>  
                    <li> {t("check-step list-element 7")}</li>  
                </ul>
                <div>
                {t("check-step any errors")}
                </div>
            </Typography>
            <FormControlLabel
                control={<Checkbox checked={checkForm.confirmation}
                    onChange={formik.handleChange}
                    size={"small"}
                    name="confirmation"
                    color={"primary"}
                />}
                label={
                    <Typography style={{color: errors.confirmation ? 'red' : 'black'}} align={"left"} variant={"subtitle2"}>
                        {t("confirmation check-step")}
                    </Typography>
            }/>
        </Container>
    )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  border-color: #61dafb;
  align-items: center;
  flex-direction: column;
`
