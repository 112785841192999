import {
    CHANGE_ADVICE_CONTENT,
    CHANGE_HEADER_LABEL,
    DisplayBoard,
    ThesisUploadingFormActions
} from "../../type";

const initialState: DisplayBoard = {
    advice: "",
    label: "",
};

export const thesisUploadingAdviceBar = (state: DisplayBoard = initialState, action: ThesisUploadingFormActions): DisplayBoard => {
    switch (action.type) {
        case CHANGE_ADVICE_CONTENT: {
            return {
                ...state,
                advice: action.payload
            };
        }
        case CHANGE_HEADER_LABEL: {
            return {
                ...state,
                advice: action.payload
            };
        }
        default:
            return state;
    }
};
