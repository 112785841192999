import {Trans} from "react-i18next";

const uploadingAdvice = {
    get: () => {
        return (
            <div style={{lineHeight: 1.5}}>
                <p>
                    <Trans>
                        uploading advice
                    </Trans>
                </p>
            </div>
        )
    },
}

export default uploadingAdvice;
