import {Route, Redirect} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux";
import useIsTokenExpired from "../hoc/useIsTokenExpired";

function PrivateRoute({children, ...rest}: React.PropsWithChildren<any>) {
    const token = useSelector((state: RootState) => state.auth.token);
    const isExpired = useIsTokenExpired(token);

    const redirectToLoginPage = (location: any) => {
        const param = {pathname: '/login', state: {from: location}};
        return <Redirect to={param}/>
    }

    return (
        <Route {...rest} render={({location}) => {
            return !isExpired ? children : redirectToLoginPage(location)
        }}/>
    )
}

export default PrivateRoute;
