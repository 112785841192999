import {AccordionSummary, List} from "@material-ui/core";
import {
    Accordion, AccordionActions,
    AccordionDetails,
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type SelectedListProps = {
    items?: Array<{ id: number, label: string, value: string }>
    onDeleteClick: (id: number) => void;
    actionButton?: React.FC
    label: string,
}

const SelectedBox: React.FC<SelectedListProps> = ({items, ...props}) => {

    const [open, setOpen] = useState<boolean>(false);

    const handleToggleButtonClick = () => setOpen(!open);

    useEffect(() => {
        !items?.length && setOpen(false);
    }, [items, open])

    const ExpandIcon = () => {
        return (
            <ExpandMoreIcon
                style={{display: items?.length ? "" : "none"}}
                onClick={handleToggleButtonClick}
            />
        )
    }

    return (
        <Accordion expanded={open}>
            <AccordionSummary expandIcon={<ExpandIcon/>}>
                <Typography>
                    {props.label} {items?.length}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List dense={true}>
                    {
                        items?.map(item => {
                            return (
                                <ListItem key={item.id} disableGutters secondaryAction={
                                    <IconButton
                                        onClick={() => props.onDeleteClick(item.id)}
                                        aria-label="delete"
                                        edge="end"
                                    >
                                        <DeleteIcon/>
                                    </IconButton>}>
                                    <ListItemText primary={item.label} secondary={item.value}/>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </AccordionDetails>
            <AccordionActions>
                {props.children}
            </AccordionActions>
        </Accordion>
    )
}

export default SelectedBox;
