import React, {useEffect, useState} from "react";
import {Autocomplete, FormControl, TextField} from "@mui/material";
import ThesisApi from "../../api/search/ThesisApi";
import IPerson from "../../dto/IPerson";
import _ from "lodash";

interface FilterSelectorProps {
    label: string,
    onChange: any
}

const SuperVisorFilterSelector: React.FC<FilterSelectorProps> = ({label, ...props}) => {

    const [supervisor, setSupervisor] = useState<IPerson[]>([])

    useEffect(() => {
        ThesisApi.fetchSupervisor()
            .then(setSupervisor)
            .catch(console.log)
    }, [])

    const removeDub = (): IPerson[] => {
        return _.uniqBy(supervisor, function (e) {
            return e.firstName + e.lastName;
        });
    }

    return (
        <FormControl fullWidth size={"small"}>
            <Autocomplete
                isOptionEqualToValue={(o, v) => {
                    return o.label === v.label;
                }}
                disablePortal
                options={removeDub().map((t) => {
                    return {label: t.firstName + " " + t.lastName, id: t.id}
                })}
                sx={{minWidth: 300}}
                size={"small"}
                onChange={(event, newValue) => props.onChange(newValue?.id)}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label={label}
                        />
                    )
                }}
            />
        </FormControl>
    )
}

export default SuperVisorFilterSelector;