import Step, {StepProps} from "./Step";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import IThesisFilesState from "../../../dto/IThesisFilesState";
import {fetchThesisFilesState, uploadSupMaterialFile, uploadThesisFile} from "../../../api/thesisFilesApi";
import {
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Typography
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import _ from "lodash";
import DropZone from "../../../component/DropZone";
import DescriptionIcon from "@material-ui/icons/Description";
import {setWorkUpFormHint} from "../../../redux/action/ThesisUploadingFromActions";
import uploadingAdvice from "../advice/uploadingAdvice";
import IThesisSpecs from "../../../dto/IThesisSpecs";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface UploaderListItemProps {
    label: string,
    text?: string
    isRequired?: boolean,
}

interface FileUploadingState {
    fileName: string | undefined,
    isLoading?: boolean
    error?: string
}


class Uploading extends Step {

    getForm = (): React.FC<StepProps> => ThesisFilesUploadingForm;

}

export default Uploading;

const ThesisFilesUploadingForm = ({formik, setProgressLabel}: StepProps): JSX.Element => {

    const history = useHistory();
    const {t, i18n} = useTranslation();

    const dispatch = useDispatch();
    const thesisSpecsForm: IThesisSpecs = formik.values;

    const [thesisFilesStateUploadingError, setThesisFileStateUploadingError] = useState<string>("");
    const [thesisAdditionalFilesUploadingError, setThesisAdditionalFilesUploadingError] = useState<string>("");

    const [isMainFileUploading, setIsMainFileUploading] = useState<boolean>(false);
    const [isAdditionalFileUploading, setIsAdditionalFileUploading] = useState<boolean>(false);

    const formState: IThesisFilesState = formik.values;
    const {thesisId} = formState;

    useEffect(() => {
        dispatch(setWorkUpFormHint(uploadingAdvice.get()))
        prepare().catch(err => console.log(err))
    }, [])

    const prepare = async () => {
        setProgressLabel("Loading");
        let [names] = await Promise.all([
            fetchThesisFilesState(thesisId),
        ]);

        formik.setFieldValue("thesisMainDocumentName", names.thesisMainDocumentName)
        formik.setFieldValue("supportDocumentName", names.supportDocumentName)
        setProgressLabel("");
    }

    const onWorkFilesAccept = (files: File[]) => {
        setIsMainFileUploading(true);
        setThesisFileStateUploadingError("");
        if (files[0].type === "application/pdf") {
            uploadThesisFile(thesisId, files[0]).then((state: IThesisFilesState) => {
                formik.setFieldValue("thesisMainDocumentName", state.thesisMainDocumentName)
                setIsMainFileUploading(false);
            }).catch((err: Error) => {
                setThesisFileStateUploadingError(err.message);
                setIsMainFileUploading(false);
            })
        } else {
            setThesisFileStateUploadingError(
                "Make sure the file you are uploading is in PDF format"
            );
        }
    }

    const onSupplementFilesAccept = (files: File[]) => {
        setIsAdditionalFileUploading(true);
        setThesisAdditionalFilesUploadingError("")
        if (true) {
            uploadSupMaterialFile(thesisId, files[0]).then((state: IThesisFilesState) => {
                setThesisAdditionalFilesUploadingError("")
                formik.setFieldValue("supportDocumentName", state.supportDocumentName)
                setIsAdditionalFileUploading(false);
            }).catch((err: Error) => {
                setThesisAdditionalFilesUploadingError(err.message)
                setIsAdditionalFileUploading(false);
            })
        } else {
            setThesisAdditionalFilesUploadingError(
                "Make sure the file you are uploading has zip, rar or 7z format"
            );
        }
    }

    const onFileRejected = (files: File[]) => {
        console.log("rejected - " + files)
    }


    const FileUploader = (props: UploaderListItemProps) => {
        return (<>
            <ListItem>
                <ListItemText
                    style={{textAlign: 'justify', color: '#4153b6'}}
                    primary={props.label}
                    secondary={props.text}
                />
                <ListItemSecondaryAction>
                    {props.isRequired && <WarningIcon style={{color: 'darkred'}}/>}
                </ListItemSecondaryAction>
            </ListItem>
            <Divider style={{background: props.isRequired ? 'darkred' : 'gray', height: 2}}/>
        </>)
    }

    return (
        <>
            <List>
                <FileUploader
                    label={t("Thesis")}
                    text={t("Thesis upload label hint text")}
                    isRequired={_.isEmpty(formState?.thesisMainDocumentName)}
                />
                <List>
                    {
                        formState?.thesisMainDocumentName &&
                        <FileUploadingIndicator
                            fileName={formState?.thesisMainDocumentName}
                            error={thesisFilesStateUploadingError}
                            isLoading={isMainFileUploading}
                        />
                    }
                </List>
                <DropZone
                    dropLabel={<div>{t("Thesis upload upload hint text")} <br/> {t("Max thesis size file")}</div>}
                    onFileAccept={onWorkFilesAccept}
                    onFileRejected={onFileRejected}
                    maxFiles={1}
                />
            </List>

            <List>
                <FileUploader
                    label={t('Supplementary Materials')}
                    text={t('Supplementary upload hint text')}
                    isRequired={false}
                />
                <List>
                    {formState?.supportDocumentName &&
                        <FileUploadingIndicator
                            fileName={formState?.supportDocumentName}
                            error={thesisAdditionalFilesUploadingError}
                            isLoading={isAdditionalFileUploading}
                        />}
                </List>
                <DropZone
                    dropLabel={<div>{t('Supplementary file upload hint text')} <br/> {t('Max supplementary size file')}
                    </div>}
                    onFileAccept={onSupplementFilesAccept}
                    onFileRejected={onFileRejected}
                    maxFiles={1}
                />
            </List>

        </>
    )
}

const FileUploadingIndicator = ({fileName = "", error = "", isLoading = false}: FileUploadingState) => {
    const hasError = !_.isEmpty(error);
    const label = hasError ? error : fileName;
    const {t, i18n} = useTranslation();

    return (
        <ListItem>
            {!hasError &&
                <ListItemAvatar>
                    <DescriptionIcon fontSize={"small"}/>
                </ListItemAvatar>}
            <Typography style={{paddingRight: 50}}>
                {label}
            </Typography>
            {!hasError &&
                <ListItemSecondaryAction>
                    {isLoading ?
                        <CircularProgress size={20}/> :
                        <div style={{marginBottom: 3}}>
                            {t('Uploaded')}
                        </div>}
                </ListItemSecondaryAction>}
        </ListItem>
    )
}
