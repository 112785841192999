import React, {useEffect} from "react";
import styled from "styled-components";
import {Divider, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchStudentWorks} from "../redux/action/ThesisActions";
import {RootState} from "../redux";
import IThesisCard from "../dto/IThesisCard";
import ThesisCard from "../component/ThesisCard";

import {useHistory} from "react-router-dom";
import {fetchFinalDocument, fetchFinalDocumentById} from "../api/thesisFilesApi";
import {useTranslation} from "react-i18next";

//logout functionality
 import {logout} from "../redux/action/AuthActions";
 //html object button
 import {Button} from "@material-ui/core";


const StudentThesesPage: React.FC = () => {
    const dispatch = useDispatch();


    const history = useHistory();
    const {t, i18n} = useTranslation();

    const {userId} = useSelector((state: RootState) => state.profile);
    const {theses} = useSelector((state: RootState) => state.thesisList);

    useEffect(() => {
        dispatch(fetchStudentWorks(userId))
    }, [dispatch, userId])

    const onButtonClick = async (thesis: IThesisCard) => {
        if (!thesis.final) history.push("/student/works/uploading", thesis)
        else await fetchFinalDocumentById(thesis.id)
    }

    const renderWorkList = (works: IThesisCard[]) => (
        works.map((thesisCard: IThesisCard, index: number) => {
            return (
                <div key={thesisCard.id}>
                    <ThesisCard onUploadButtonClick={onButtonClick} thesis={thesisCard}/>
                    <Divider style={{margin: 10}} flexItem/>
                </div>
            )
        })
    )

    const renderEmptyList = () => (
        <Typography variant={"h6"} color={"textSecondary"} key={0}>
            You don't have any assigned thesis yet.
        </Typography>
    )

    return (
        <Container>
            {/*logOut button  */}
            <div style={{display: "flex", justifyContent: "flex-end", paddingBottom:"20px"}}>
                <div>
                    <Button 
                        onClick={() => dispatch(logout())} 
                        variant="text"
                        size={"small"} 
                        color={"primary"}
                    >
                        <u>
                        Logout
                        </u>
                    </Button>
                </div>
            </div>
            {/* -------- */}
            <div>
                <Typography gutterBottom align={"left"} variant={"h4"} component={"h1"} color={"primary"}>
                    {t('Your theses')}
                </Typography>
            </div>
            <div>
            <Typography key={"h"}align={"left"} component={"p"} color={"textSecondary"}>
                {t('Your theses description')}
            </Typography>
            </div>
            <WorkListContainer>
                {theses.length ? renderWorkList(theses) : renderEmptyList()}
            </WorkListContainer>
        </Container>
    )
}

export default StudentThesesPage

const Container = styled.div`
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  flex-direction: column;
  padding: 40px;
  background-color: #FFF;
  align-items: stretch;
  border-radius: 15px;
  max-width: 680px;
  min-width: 560px;
  margin: 0 auto;
  display: flex;
`;

const WorkListContainer = styled.div`
  flex-direction: column;
  padding-top: 20px;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 200px;
`
