import Passport from "../../dto/Passport";

export const AUTHENTICATION_PROCESS = "AUTHENTICATION_PROCESS";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";

export const AUTO_LOGOUT_TIME_UPDATE = "AUTO_LOGOUT_TIME";
export const LOGOUT = "LOGOUT";

export const ACCESS_TOKEN_REFRESHING = "ACCESS_TOKEN_REFRESHING";
export const ACCESS_TOKEN_REFRESHED = "ACCESS_TOKEN_REFRESHED";
export const REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED";


interface AccessTokenRefreshing {
    type: typeof ACCESS_TOKEN_REFRESHING,
}

interface AccessTokenRefreshed {
    type: typeof ACCESS_TOKEN_REFRESHED,
    payload: string,
}

interface AccessTokenRefreshFailed {
    type: typeof REFRESH_TOKEN_EXPIRED,
    payload: string,
}

interface Logout {
    type: typeof LOGOUT,
}

interface Authenticate {
    type: typeof AUTHENTICATION_PROCESS,
}

interface AuthenticationSuccess {
    type: typeof AUTHENTICATION_SUCCESS,
    payload: Passport,
}

interface AuthenticationFail {
    type: typeof AUTHENTICATION_FAIL,
    payload: string
}

interface AutoLoginTimeUpdate {
    type: typeof AUTO_LOGOUT_TIME_UPDATE,
    payload: number,
}

export type AuthActionTypes = Logout | Authenticate
    | AuthenticationSuccess | AuthenticationFail | AutoLoginTimeUpdate
    | AccessTokenRefreshed | AccessTokenRefreshing | AccessTokenRefreshFailed
