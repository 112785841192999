import {Button, Card, CardActionArea, CardActions, CardContent, Typography} from "@material-ui/core";
import React from "react";
import IThesisCard from "../dto/IThesisCard";
import styled from "styled-components";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import {useTranslation} from "react-i18next";

interface ThesisCardProps {
    key?: string | number;
    thesis: IThesisCard,
    onDownloadButtonClick?: any,
}

const FileCard = ({thesis, onDownloadButtonClick}: ThesisCardProps) => {
    const {t, i18n} = useTranslation();

    return (
        <Card elevation={0} style={{textAlign: 'left', width: "100%"}}>
            <CardActions style={{padding: 15, backgroundColor: '#f8f8f8', justifyContent: 'space-between'}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <InsertDriveFileIcon/>
                    <Typography style={{marginLeft: 15}} variant={"subtitle1"}>
                        {t("Final Thesis Document")}
                    </Typography>
                </div>
                <Button
                    onClick={() => onDownloadButtonClick(thesis)}
                    variant={"contained"}
                    color="primary"
                    size="small"
                >
                    Download
                </Button>
            </CardActions>
            <CardActionArea>
                <CardContent>
                    <table>
                        <tbody>
                        <tr key={"work-state"}>
                            <Label>{t('Status')}:</Label>
                            <Value>{t('Ready to confirm')}</Value>
                        </tr>
                        </tbody>
                    </table>
                </CardContent>
            </CardActionArea>
        </Card>

    )
}

export default FileCard;


const Value = styled.th`
  padding: 5px;
  font-weight: lighter;
`;

const Label = styled.th`
  color: #234771;
  font-weight: bold;
  padding: 5px;
  min-width: 80px;
`;

/*




 */

