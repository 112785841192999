import React from 'react';
import './App.css';
import {store} from "./redux";
import styled from "styled-components";
import {Provider} from "react-redux";
import RouteMap from "./routes/RouteMap";
import "./i18n"

function App() {
    return (
        <Provider store={store}>
            <MainContainer className="App">
                <RouteMap/>
            </MainContainer>
        </Provider>
    );
}

export default App;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
