import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route} from "react-router";
import {RootState} from "../redux";
import useIsTokenExpired from "../hoc/useIsTokenExpired";
import {fetchNewAccessToken} from "../redux/action/AuthActions";

function AuthRoute({children, ...rest}: React.PropsWithChildren<any>) {
    const token = useSelector((state: RootState) => state.auth.token);
    const isRefreshTokenExpired = useSelector((state: RootState) => state.auth.isRefreshTokenExpired);
    const isExpired = useIsTokenExpired(token);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isExpired && !isRefreshTokenExpired) {
            dispatch(fetchNewAccessToken())
            console.log("run")
        }
    }, [isExpired, isRefreshTokenExpired])


    const redirectToRolePage = (location: any) => {
        const param = {pathname: '/login/role', state: {from: location}};
        return <Redirect to={param}/>
    }

    return (
        <Route {...rest} render={({location}) => {
            return !isExpired ? redirectToRolePage(location) : children
        }}/>
    )
}

export default AuthRoute;

