import {Dispatch} from "react";
import {
    WORK_FETCH_FAIL,
    WORK_FETCH_SUCCESS,
    WORKS_FETCHING,
    WorkActionTypes
} from "../type/thesisTypes";
import IThesisCard from "../../dto/IThesisCard";
import ThesisApi from "../../api/thesisApi";

const {fetchStudentTheses} = ThesisApi;

export const fetchStudentWorks = (studentId: number) => {
    return async (dispatch: Dispatch<WorkActionTypes>, getState: Function) => {
        dispatch({type: WORKS_FETCHING})
        fetchStudentTheses(studentId)
            .then((works) => onWorksFetchSuccess(dispatch, works))
            .catch(error => onWorksFetchFail(dispatch, error))
    }
}

const onWorksFetchSuccess = (dispatch: Dispatch<WorkActionTypes>, works: IThesisCard[]) => {
    dispatch({type: WORK_FETCH_SUCCESS, payload: works})
}

const onWorksFetchFail = (dispatch: Dispatch<WorkActionTypes>, error: Error) => {
    dispatch({type: WORK_FETCH_FAIL, payload: error.message})
}
