const DepartmentEnum =
    new Map([
        [3, "Part Time"],
        [5, "Day"],
        [6, "Evening"],
        [7, "Distance"],
        [8, "Distance (c)"],
        [9, "Module"],
    ])

export default DepartmentEnum;

/*
3 – заочное
5- дневное
6 – вечернее
7- дистанционное
8- дистанционное контактное
9 – модульное
 */