import {combineReducers} from 'redux';
import {authReducer} from "./person/AuthReducer";
import {profileReducer} from "./person/ProfileReducer";
import {thesisUploadingAdviceBar} from "./student/ThesisUploadingAdviceBar";
import thesisListReducer from "./ThesisListReducer";


export const rootReducer = combineReducers({
    thesisUploadingAdviceBar: thesisUploadingAdviceBar,
    profile: profileReducer,
    auth: authReducer,
    thesisList: thesisListReducer
});

export type RootState = ReturnType<typeof rootReducer>;
