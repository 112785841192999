import React from "react";
import IThesis from "../../dto/search/IThesis";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Table as MUTable} from "@material-ui/core";
import ThesesRow from "./ThesesRow";
import {CircularProgress, IconButton, Paper} from "@mui/material";
import styled from "@emotion/styled";

type TableProps = {
    isLoading: boolean
    data: Array<IThesis>
    onRowClick: (id: number) => void
    onColumnClick: (name: string) => void
    onCheckBoxClick: (thesis: IThesis) => void
    selectedTheses: Array<IThesis>
    columns: Array<{ label: string, name: string }>,
};

const Table: React.FC<TableProps> = (props) => {

    const classes = useStyles();

    const renderLoadingIndicator = () => {
        return (
            <Backdoor>
                <Center>
                    <CircularProgress/>
                </Center>
            </Backdoor>
        )
    }

    return (
        <div style={{position: 'relative'}}>
            {props.isLoading && renderLoadingIndicator()}
            <TableContainer component={Paper} className={classes.table}>
                <MUTable size={"small"}>
                    <TableHead>
                        <TableRow key={"header"} className={classes.header}>
                            {props.columns.map(col => (
                                <TableCell key={col.name} className={classes.headerCell}>
                                    <div onClick={() => props.onColumnClick(col.name)} style={{display: 'flex'}}>
                                        <IconButton
                                            onClick={() => props.onColumnClick(col.name)}
                                            style={{fontSize: 16, padding: 0, color: 'white'}}>
                                            {col.label}
                                        </IconButton>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map(thesis => {
                            const isSelected = props.selectedTheses.find(t => t.id === thesis.id)
                            return (
                                <ThesesRow
                                    onCheckBoxClick={props.onCheckBoxClick}
                                    onInfoClick={props.onRowClick}
                                    isSelected={!!isSelected}
                                    thesis={thesis}
                                />
                            )
                        })}
                    </TableBody>
                </MUTable>
            </TableContainer>
        </div>
    )
}

export default Table;

const Center = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const Backdoor = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`;

const useStyles = makeStyles({
    header: {backgroundColor: "#234771"},
    headerCell: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold'
    },
    table: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#dddd",
    },
    row: {},
});

