import Step, {StepProps} from "./Step";
import React, {useEffect} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import IThesisSpecs from "../../../dto/IThesisSpecs";
import IThesisCard from "../../../dto/IThesisCard";
import {useLocation} from "react-router-dom";
import Section from "../../../component/form/Section";
import Radio from "../../../component/form/Radio";
import NumberInput from "../../../component/form/NumberInput";
import KeysTable from "../../../component/form/KeysTable";
import styled from "styled-components";
import {setWorkUpFormHint} from "../../../redux/action/ThesisUploadingFromActions";
import {useDispatch} from "react-redux";
import ThesisDetailsHints from "../advice/thesisDetails";
import {useTranslation} from "react-i18next";
import thesisApi from "../../../api/thesisApi";

class ThesisDetails extends Step {

    getForm = (): React.FC<StepProps> => ThesisDetailsForm;

}

const ThesisDetailsForm = ({formik, setProgressLabel}: StepProps): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();

    const {t, i18n} = useTranslation();
    const chosenThesisToUpload: IThesisCard = location.state as IThesisCard;
    const thesisSpecsForm: IThesisSpecs = formik.values;

    useEffect(() => {
        dispatch(setWorkUpFormHint(ThesisDetailsHints.general.hint()));
        thesisApi.fetchThesisSpecs(chosenThesisToUpload.id)
            .then(onThesisFetchSuccess)
            .catch(onThesisFetchFail)
    }, [])

    const onThesisFetchSuccess = (specs: IThesisSpecs) => {
        setProgressLabel("");
        formik.setValues({...formik.values, ...specs})
    }

    const onThesisFetchFail = (err: Error) => console.log(err.message)

    const onSectionHover = (label: string, advice: string, isHovered: boolean) => {
        if (isHovered) dispatch(setWorkUpFormHint(advice))
        else dispatch(setWorkUpFormHint(ThesisDetailsHints.general.hint()));
    }

    const onThesesParamFocus = (advice: any) => {
        dispatch(setWorkUpFormHint(advice()));
    }


    const renderLanguageSelector = () => {
        const {hint} = ThesisDetailsHints.langSection;
        return (
            <Section id={"language-selector"} label={t('Thesis Language')} onHover={onSectionHover} hint={hint()}>
                <CheckBoxContainer>
                    <Radio
                        id={"work-lang-lv"} label={t('Latvian')} value={0} name={"languageId"}
                        onChange={() => formik.setFieldValue("languageId", 0)}
                        checked={thesisSpecsForm.languageId === 0}
                        key={"work-lang-lv"}
                    />
                    <Radio
                        id={"work-lang-en"} label={t('English')} value={1} name={"languageId"}
                        onChange={() => formik.setFieldValue("languageId", 1)}
                        checked={thesisSpecsForm.languageId === 1}
                        key={"work-lang-en"}
                    />
                    <Radio
                        id={"work-lang-ru"} label={t('Russian')} value={2} name={"languageId"}
                        onChange={() => formik.setFieldValue("languageId", 2)}
                        checked={thesisSpecsForm.languageId === 2}
                        key={"work-lang-ru"}
                    />
                </CheckBoxContainer>
            </Section>
        )
    }

    const renderWorkParamsTable = () => {
        const {paramSection} = ThesisDetailsHints;

        const paramFields = ["pageCount", "imageCount", "tableCount", "referenceCount", "appendixCount"];
        const errorsFields = Object.keys(formik.errors);
        const hasError = errorsFields.some(r => paramFields.includes(r))

        const renderErrorMessage = () => (
            <caption style={{color: 'red', padding: 10}}>
                All fields are required
            </caption>)

        return (
            <Section
                id={"work-params"}
                label={t('Work Parameters')}
                hint={paramSection.hint()}
                onHover={onSectionHover}
            >
                <TableContainer>
                    <Table size="small">
                        {hasError ? renderErrorMessage() : null}
                        <TableHead>
                            <TableRow>
                                <TableCell padding={"none"} key={"header-pages"} align="center">
                                    {t('Pages')}
                                </TableCell>
                                <TableCell padding={"none"} key={"header-tables"} align="center">
                                    {t('Tables')}
                                </TableCell>
                                <TableCell padding={"none"} key={"header-pictures"} align="center">
                                    {t('Figures')}
                                </TableCell>
                                <TableCell padding={"none"} key={"header-appendix"} align="center">
                                    {t('Appendix')}
                                </TableCell>
                                <TableCell padding={"none"} key={"header-literature"} align="center">
                                    {t('Literature')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell padding={"none"} key={"param-pages"} align="center">
                                    <NumberInput
                                        maxLength={3}
                                        name={"pageCount"}
                                        value={thesisSpecsForm.pageCount.toString()}
                                        onFocus={() => onThesesParamFocus(paramSection.pages)}
                                        onChange={formik.handleChange}
                                        hasError={formik.errors["pageCount"]}
                                    />
                                </TableCell>
                                <TableCell padding={"none"} key={"param-tables"} align="center">
                                    <NumberInput
                                        value={thesisSpecsForm.tableCount.toString()}
                                        onFocus={() => onThesesParamFocus(paramSection.tables)}
                                        name={"tableCount"}
                                        onChange={formik.handleChange}
                                        hasError={formik.errors["tableCount"]}
                                        maxLength={3}
                                    />
                                </TableCell>
                                <TableCell padding={"none"} key={"param-pictures"} align="center">
                                    <NumberInput
                                        value={thesisSpecsForm.imageCount.toString()}
                                        onFocus={() => onThesesParamFocus(paramSection.figures)}
                                        name={"imageCount"}
                                        onChange={formik.handleChange}
                                        hasError={formik.errors["imageCount"]}
                                        maxLength={3}
                                    />
                                </TableCell>
                                <TableCell padding={"none"} key={"param-appendix"} align="center">
                                    <NumberInput
                                        value={thesisSpecsForm.appendixCount.toString()}
                                        onFocus={() => onThesesParamFocus(paramSection.appendix)}
                                        onChange={formik.handleChange}
                                        hasError={formik.errors["appendixCount"]}
                                        name={"appendixCount"}
                                        maxLength={3}
                                    />
                                </TableCell>
                                <TableCell padding={"none"} key={"param-literature"} align="center">
                                    <NumberInput
                                        value={thesisSpecsForm.referenceCount.toString()}
                                        onFocus={() => onThesesParamFocus(paramSection.literature)}
                                        name={"referenceCount"}
                                        hasError={formik.errors["referenceCount"]}
                                        onChange={formik.handleChange}
                                        maxLength={3}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>
        )
    }

    const renderKeySelector = () => {
        const paramFields = ["keyWordsLv", "keyWordsEn"];
        const errorsFields = Object.keys(formik.errors);
        const hasError = errorsFields.some(r => paramFields.includes(r))

        const {keysSection} = ThesisDetailsHints;
        const {hint} = keysSection;

        const onKeyWordAdd = (keys: string[]) => {
            const {keyWordsLv, keyWordsEn} = thesisSpecsForm;
            formik.setFieldValue("keyWordsLv", [...keyWordsLv, keys[0].toUpperCase()])
            formik.setFieldValue("keyWordsEn", [...keyWordsEn, keys[1].toUpperCase()])
        }

        const onKeyWordRemove = (index: number) => {
            const updatedKeyWordsLv = thesisSpecsForm.keyWordsLv.filter((key, i) => i !== index);
            const updatedKeyWordsEn = thesisSpecsForm.keyWordsEn.filter((key, i) => i !== index);
            formik.setFieldValue("keyWordsLv", updatedKeyWordsLv)
            formik.setFieldValue("keyWordsEn", updatedKeyWordsEn)
        }

        return (
            <Section
                id={"work-keys"}
                label={t('Theses Keywords')}
                hint={hint()}
                onHover={onSectionHover}
            >
                <KeysTable
                    onKeyWordAdd={onKeyWordAdd}
                    onKeyWordRemove={onKeyWordRemove}
                    labels={[t('Latvian'), t('English')]}
                    rows={[thesisSpecsForm.keyWordsLv, thesisSpecsForm.keyWordsEn]}
                    max={5}
                />
                {hasError && <Typography style={{color: 'red', fontSize: 14}}>
                    You need to add a minimum of three keywords.
                </Typography>}
            </Section>
        )
    }


    return (
        <div>
            {renderLanguageSelector()}
            {renderWorkParamsTable()}
            {renderKeySelector()}
        </div>
    )
}

export default ThesisDetails;

const CheckBoxContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 260px;
`

const NamesInputContainer = styled.div`
  width: 100%;
  display: flex
`

