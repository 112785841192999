export const CHANGE_ADVICE_CONTENT = "CHANGE_ADVICE_CONTENT";
export const CHANGE_HEADER_LABEL = "CHANGE_HEADER_LABEL";

export interface DisplayBoard {
    advice: String;
    label: String;
}

interface ChangeAdviceContent {
    type: typeof CHANGE_ADVICE_CONTENT,
    payload: string
}

interface ChangeLabel {
    type: typeof CHANGE_HEADER_LABEL,
    payload: string
}

export type ThesisUploadingFormActions = ChangeAdviceContent | ChangeLabel;
