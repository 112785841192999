import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import ThesisApi from "../../api/search/ThesisApi";
import IFaculty from "../../dto/IFaculty";
import IGrade from "../../dto/IGrade";
import thesisApi from "../../api/thesisApi";

interface FilterSelectorProps {
    label: string,
    onChange: any
}

const GradeSelector: React.FC<FilterSelectorProps> = ({label, ...props}) => {

    const [grades, setGrades] = useState<IGrade[]>([])

    useEffect(() => {
         ThesisApi.fetchGrades()
             .then(setGrades)
             .catch(console.log)
    }, [])


    const renderItems = () => grades
        .map(({mark, id}) => (
            <MenuItem key={mark} value={id}>
                {mark}
            </MenuItem>
        ))

    return (
        <FormControl fullWidth size={"small"}>
            <InputLabel id="grade-select">{label}</InputLabel>
            <Select style={{backgroundColor: "white"}} label={label} defaultValue={'-1'} onChange={props.onChange}>
                <MenuItem value={-1} id={"none"}>
                    <em> --- </em>
                </MenuItem>
                  <MenuItem value={0} id={"na"}>
                    <em>N/A</em>
                </MenuItem>     
                {renderItems()}
            </Select>
        </FormControl>
    )
}

export default GradeSelector;
