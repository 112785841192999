const DepartmentEnumLv =
    new Map([
        [3, "Nepilna laika"],
        [5, "Dienas"],
        [6, "Vakara"],
        [7, "Tālmācības"],
        [8, "Tālmācības (k)"],
        [9, "Moduļu"],
    ])

export default DepartmentEnumLv;