import {
    ACCESS_TOKEN_REFRESHING,
    AuthActionTypes,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_PROCESS,
    AUTHENTICATION_SUCCESS,
    LOGOUT,
    REFRESH_TOKEN_EXPIRED,
} from "../type";
import {Dispatch} from "react";
import {RootState} from "../reducer";
import {AuthApi} from "../../api/authApi";
import Passport from "../../dto/Passport";
import IAccessTokenBody from "../../token/IAccessTokenBody";
import jwtDecode from "jwt-decode";

const {
    checkCredentials,
    refreshAccessToken
} = AuthApi

export const authenticate = (login: string, password: string) => {
    return async (dispatch: Dispatch<AuthActionTypes>, state: RootState) => {
        dispatch({type: AUTHENTICATION_PROCESS})
        checkCredentials(login, password)
            .then((passport: Passport) => onAuthSuccess(dispatch, passport))
            .catch((error) => onAuthFail(dispatch, error))
    }
}

const onAuthSuccess = (dispatch: Dispatch<AuthActionTypes>, passport: Passport) => {
    const decoded: IAccessTokenBody = jwtDecode(passport.accessToken);
    const {uid, rol} = decoded;

    dispatch({
        type: AUTHENTICATION_SUCCESS,
        payload: {
            accessToken: passport.accessToken,
            userId: uid,
            roles: parseRoles(rol),
        }
    })
}

const onAuthFail = (dispatch: Dispatch<AuthActionTypes>, error: Error) => {
    dispatch({
        type: AUTHENTICATION_FAIL,
        payload: error.message
    })
}


export const fetchNewAccessToken = () => {
    return async (dispatch: Dispatch<AuthActionTypes>, state: Function) => {
        dispatch({type: ACCESS_TOKEN_REFRESHING})
        refreshAccessToken().then((token: string) => {
            const decoded: IAccessTokenBody = jwtDecode(token);
            const {uid, rol} = decoded;
            dispatch({
                type: AUTHENTICATION_SUCCESS,
                payload: {
                    accessToken: token,
                    userId: uid,
                    roles: parseRoles(rol),
                }
            })
        }).catch((err) => dispatch({type: REFRESH_TOKEN_EXPIRED, payload: err}));
    }
}

const parseRoles = (jwtRol: string) => {
    return jwtRol
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll(",", "")
        .split(" ")
}


export const logout = () => {
    return async (dispatch: Dispatch<AuthActionTypes>, state: Function) => {
        await AuthApi.logout();
        dispatch({type: LOGOUT})
    }
}