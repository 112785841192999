import * as Yup from "yup";

const DescriptionFormSchema = Yup.object().shape({
    keyWordsLv: Yup.array().min(3, ""),
    keyWordsEn: Yup.array().min(3, ""),

    pageCount: Yup.number().required(),
    imageCount: Yup.number().required(),
    tableCount: Yup.number().required(),
    referenceCount: Yup.number().required(),
    appendixCount: Yup.number().required(),

    //  studentNameEn: Yup.string().required(),
    //  supervisorNameEn: Yup.string().required(),
    //  studentNameRu: Yup.string().when("languageId", {
    //      is: (val: number) => val === 2,
    //      then: Yup.string().required(),
    //     otherwise: Yup.string().notRequired()
    //  }),
    //  supervisorNameRu: Yup.string().when("languageId", {
    //       is: (val: number) => val === 2,
    //       then: Yup.string().required(),
    //      otherwise: Yup.string().notRequired()
    //   }),
});

export default DescriptionFormSchema;
