import {BrowserRouter} from "react-router-dom";
import LoginPage from "../containers/LoginPage";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import RoleSelectPage from "../containers/RoleSelectPage";
import StudentThesesPage from "../containers/StudentThesesPage";
import {ThesisUploadingPage} from "../containers/ThesisUploadingPage";
import {Redirect, Route} from "react-router";
import SearchingPage from "../containers/SearchingPage";

const RouteMap = () => {
    return (
        <BrowserRouter>
            <Route path='/*' exact>
                <Redirect to={"/login"}/>
            </Route>
            <AuthRoute exact path="/login">
                <LoginPage/>
            </AuthRoute>
            <PrivateRoute exact path="/login/role">
                <RoleSelectPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/student/works">
                <StudentThesesPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/student/works/uploading">
                <ThesisUploadingPage/>
            </PrivateRoute>
            <Route exact path={"/search"}>
                <SearchingPage/>
            </Route>
        </BrowserRouter>
    )
}

export default RouteMap
