import IThesisCard from "../../dto/IThesisCard";

import {
    WORK_FETCH_FAIL,
    WORK_FETCH_SUCCESS,
    WORKS_FETCHING,
    WorkActionTypes,
} from "../type/thesisTypes";

interface ThesisListReducer {
    theses: IThesisCard[]
    isFetching: boolean
    error: string,
}

const initialState: ThesisListReducer = {
    theses: [],
    isFetching: false,
    error: '',
};

export const thesisListReducer = (
    state: ThesisListReducer = initialState,
    action: WorkActionTypes
): ThesisListReducer => {

    switch (action.type) {
        case WORKS_FETCHING: {
            return {
                ...state,
                isFetching: true
            };
        }
        case WORK_FETCH_SUCCESS: {
            return {
                ...state,
                theses: action.payload,
                isFetching: false
            };
        }
        case WORK_FETCH_FAIL: {
            return {
                ...state,
                isFetching: false,
                error: action.payload
            };
        }
        default:
            return state;
    }
};

export default thesisListReducer
