import styled from "styled-components";
import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";

type RoleProps = {
    id: string,
    onHover?: Function,
    onClick?: Function,
    isSelected: boolean,
    label?: string,
    Icon: any,
}

const Role = (props: RoleProps) => {

    const {Icon, isSelected} = props;
    const [isHover, setIsHover] = useState<Boolean>(false);

    const iconStyle = {color: !(isHover || isSelected) ? "lightgray" : "#234771", fontSize: 45}
    const labelStyle = {color: !(isHover || isSelected) ? "lightgray" : "#234771"}

    useEffect(() => {
        props.onHover && props.onHover(props.id, isHover);
    }, [isHover])

    return (
        <RoleContainer
            id={props.id}
            onClick={() => props.onClick && props.onClick(props.id)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <IconContainer active={isSelected || isHover}>
                <Icon style={iconStyle}/>
            </IconContainer>
            <LabelContainer>
                <Typography style={labelStyle}>
                    {props.label}
                </Typography>
            </LabelContainer>
        </RoleContainer>
    )
}

const LabelContainer = styled.div`
  padding: 10px;
`

const IconContainer = styled.div<{ active: Boolean }>`
  border: 3px solid;
  border-color: ${props => props.active ? "#234771" : "lightgray"};
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`

const RoleContainer = styled.div`
  border: 1px solid #ffffff;
`

export default Role;