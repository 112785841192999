import Step, {StepProps} from "./Step";
import React, {useEffect, useState} from "react";
import IThesisSpecs from "../../../dto/IThesisSpecs";
import thesisApi from "../../../api/thesisApi";
import styled from "styled-components";
import {Accordion, AccordionDetails, Typography, withStyles} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextArea from "../../../component/form/TextArea";
import {useDispatch} from "react-redux";
import {setWorkUpFormHint} from "../../../redux/action/ThesisUploadingFromActions";
import abstractAdvice from "../advice/abstractAdvice";
import ErrorIcon from '@material-ui/icons/Warning';
import _ from "lodash";
import {useTranslation} from "react-i18next";

class Abstract extends Step {

    getForm = (): React.FC<StepProps> => ThesisAbstractsForm;

}

export default Abstract;

const ThesisAbstractsForm = ({formik, setProgressLabel}: StepProps): JSX.Element => {
    const {t, i18n} = useTranslation();

    const thesisSpecsForm: IThesisSpecs = formik.values
    const [expanded, setExpanded] = useState<string | boolean>("panel1");

    const handleChange = (panel: string) => (event: any, isExpanded: string | boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setWorkUpFormHint(abstractAdvice.get()))
    }, [dispatch])

    useEffect(() => {
        thesisSpecsForm.studentNameEn = thesisSpecsForm.studentNameLv;
        thesisApi.saveThesisSpecs(thesisSpecsForm.thesisId, thesisSpecsForm).catch(err => console.log(err))
    }, [thesisSpecsForm])

    const englishAbstractSectionError =
        !_.isEmpty(formik.errors.studentNameEn) ||
        !_.isEmpty(formik.errors.supervisorNameEn) ||
        !_.isEmpty(formik.errors.abstractEn);


    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} key={"abstract-lv"}>

                <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={"lv-abstract-header"}>
                    {!_.isEmpty(formik.errors.abstractLv) && <WarningIcon/>}
                    <Typography color={"primary"}>
                        {t('Abstract in latvian')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{padding: 0}}>
                    <AbstractInputContainer>
                        <TextArea
                            error={!_.isEmpty(formik.errors.abstractEn)}
                            id={"abstract-lv"} name={"abstractLv"}
                            onChange={formik.handleChange}
                            value={thesisSpecsForm.abstractLv}
                            limit={1300}
                            rows={14}
                        />
                    </AbstractInputContainer>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} key={"abstract-en"}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={"en-abstract-header"}>
                    {englishAbstractSectionError && <WarningIcon/>}
                    <Typography color={"primary"}>
                        {t('Abstract in english')}
                    </Typography>
                </AccordionSummary>

                <AccordionDetails style={{padding: 0}}>
                    <AbstractInputContainer>
                        <TextArea
                            error={!_.isEmpty(formik.errors.abstractEn)}
                            rows={14} id={"abstract-en"} name={"abstractEn"}
                            onChange={formik.handleChange}
                            value={thesisSpecsForm.abstractEn}
                            limit={1300}
                        />
                    </AbstractInputContainer>
                </AccordionDetails>
            </Accordion>

        </div>
    )
}

const WarningIcon = styled(ErrorIcon)({paddingRight: 10, color: 'red'});

const AbstractInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        '&$expanded': {minHeight: 56,},
        marginBottom: -1,
    },
    content: {'&$expanded': {margin: '12px 0',}},
    expanded: {},
})(MuiAccordionSummary);
