import React, {useEffect, useState} from 'react';
import {Button, Container, Pagination, Paper, SelectChangeEvent,} from "@mui/material";
import ThesisApi from "../api/search/ThesisApi";
import ThesisSearchFilterParams from "../model/ThesisSearchFilterParams";
import SpecialityFilterSelector from './selector/SpecialityFilterSelector';
import IThesis from "../dto/search/IThesis";
import FacultyFilterSelector from "./selector/FacultyFilterSelector";
import YearFilterSelector from "./selector/YearFilterSelector";
import styled from "styled-components";
import Table from "../component/table/Table";
import StudentNumberSelector from "./selector/StudentNumberSelector";
import ThesisDialog from "../component/ThesisDialog";
import SelectedBox from "../component/SelectedBox";
import SuperVisorFilterSelector from "./selector/SuperVisorFilterSelector";
import {useTranslation} from "react-i18next";

//logout functionality
 import {logout} from "../redux/action/AuthActions";
 // required for logout functionality
 import {useDispatch} from "react-redux";
import GradeSelector from './selector/GradeSelector';

 const SearchingPage = () => {
    const dispatch = useDispatch();

    const {t, i18n} = useTranslation();

    const cols: Array<{ label: string, name: string }> = [
        {label: "", name: "check"},
        //{label: t('St number'), name: "St Number"},
        {label: t('St number'), name: "stNumber"},
        {label: t('Faculty'), name: "facultyName"},
        {label: t('Speciality'), name: "specialityName"},
        {label: t('Path'), name: "department"},
        {label: t('Author'), name: "studentFirstName"},
        {label: t('Name'), name: "thesisNameEn"},
        {label: t('Supervisor'), name: "supervisorFullName"},
        {label: t('Reviewer'), name: "reviewerName"},
        {label: t('Year'), name: "graduatedYear"},
        {label: t('Semester'), name: "semester"},
        {label: t('grade'), name: "idMark"},
        //{label: t('Uploaded'), name: "uploaded"},
        {label: t('dateColum'), name: "confirmedDate"},

        {label: t('Info'), name: "info"},
    ]


    const [theses, setTheses] = useState<{ list: IThesis[], count: number }>({list: [], count: 0})
    const [selected, setSelected] = useState<Array<IThesis>>([]);

    const [open, setOpen] = React.useState(false);

    const [filter, setFilter] = useState<ThesisSearchFilterParams>({
        stNumber: undefined,
        sortAcceding: undefined,
        sortByField: undefined,
        specialityId: undefined,
        facultyName: undefined,
        gradYear: undefined,
        mark: undefined,
        supervisorId: undefined,
        reviewerId: undefined,
        pageIndex: 1,
        pageSize: 10
    })

    const [isFetching, setIsFetching] = useState<boolean>(false);

    useEffect(() => {
        setIsFetching(true);
        Promise.all([ThesisApi.fetchTheses(filter), ThesisApi.countTheses(filter)])
            .then((result) => {
                const theses = {list: result[0], count: result[1]}
                setTheses(theses);
                setIsFetching(false);
            }).catch(e => {
            setIsFetching(false);
            //console.log(e)
        })
    }, [filter])

    //console.log(theses)

    const handlePageChange = (event: any, value: number) => {
        setFilter({...filter, pageIndex: value});
    }

    const [clickedInfoTheses, setClickedInfoTheses] = useState<IThesis | undefined>(undefined)

    const handleRowClick = (id: number) => {
        const selected = theses.list.find(t => t.id === id);
        setClickedInfoTheses(selected);
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    const handleCheckBoxClick = (t: IThesis) => {
        const isSelected = selected.filter(thesis => thesis.id === t.id)
        const newSelection = isSelected.length ?
            selected.filter(thesis => thesis.id !== t.id) :
            [...selected, t]

        setSelected(newSelection);
    }

    const handleDownloadArchive = () => {
        ThesisApi.downloadFinalTheseArchive(selected.map(t => t.id.toString()))
    }

    const handleHeaderColumnNameClick = (name: string) => {
        let sortAcceding = !filter.sortAcceding
        if (name !== filter.sortByField) sortAcceding = false;
        setFilter({...filter, sortByField: name, sortAcceding})
    }

    const handleFilterChange = (e: SelectChangeEvent, field: string) => {
        let id: any = e.target.value;
        if (id === '' || id === -1) id = undefined;
        setFilter({...filter, [field]: id, pageIndex: 1})
    }

    const handleFilterChangeById = (id: number | undefined, field: string) => {
        if (id === 0) id = undefined;
        setFilter({...filter, [field]: id, pageIndex: 1})
    }

    const handleLogout = () => {
        dispatch(logout())
        window.location.reload()
      };

    return ( 
        <Container maxWidth={false}>
            <PageContainer>
                <HeaderContainer>
                    {/*logOut button  */}
                    <div style={{display: "flex", justifyContent: "flex-end", paddingBottom:"20px"}}>
                        <div>
                            <Button 
                                onClick={() => handleLogout()} 
                                size={"small"} 
                                color={"primary"}
                                variant="text"
                            >
                                <u>
                                    Logout
                                </u>
                            </Button> 

                        </div>
                    </div> 
                    {/* -------- */}


                    <div style={{display: 'flex', flex: 1}}>
                        <div style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <Distance width={200}>
                                <StudentNumberSelector
                                    onChange={(e: SelectChangeEvent) => handleFilterChange(e, "stNumber")}
                                    label={t('Student Number')}
                                />
                            </Distance>
                            <Distance minWidth={200}>
                                <SpecialityFilterSelector
                                    onChange={(e: SelectChangeEvent) => handleFilterChange(e, "specialityId")}
                                    label={t('Speciality')}
                                />
                            </Distance>
                            <Distance width={110}>
                                <FacultyFilterSelector
                                    onChange={(e: SelectChangeEvent) => handleFilterChange(e, "facultyName")}
                                    label={t('Faculty Name')}
                                />
                            </Distance>
                            <Distance width={110}>
                                <YearFilterSelector
                                    onChange={(e: SelectChangeEvent) => handleFilterChange(e, "gradYear")}
                                    label={t('Year')}
                                />
                            </Distance>
                            <Distance width={110}>
                                <GradeSelector
                                    onChange={(e: SelectChangeEvent) => handleFilterChange(e, "mark")}
                                    label={t('grade')}
                                />
                            </Distance>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Pagination
                                color={"primary"}
                                onChange={handlePageChange}
                                count={Math.ceil(theses.count / filter.pageSize)}
                                page={filter.pageIndex}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', flex: 1, paddingTop: 20}}>
                        <div style={{display: "flex", flexDirection: "row",}}>
                            <SuperVisorFilterSelector
                                label={t('Supervisor')}
                                onChange={(id: number | undefined) => handleFilterChangeById(id, "supervisorId")}
                            />
                        </div>
                        <div style={{display: "flex", paddingLeft: 20, flexDirection: "row"}}>
                            <SuperVisorFilterSelector
                                label={t('Reviewer')}
                                onChange={(id: number | undefined) => handleFilterChangeById(id, "reviewerId")}
                            />
                        </div>
                    </div>


                </HeaderContainer>

                <div style={{height: 50, position: "relative", marginBottom: 10}}>
                    <div style={{position: 'absolute', right: 0, zIndex: 1000, minWidth: 500}}>
                        <SelectedBox
                            label={t('Selected Theses')}
                            onDeleteClick={(id) => setSelected(selected.filter(f => f.id !== id))}
                            items={selected.map(theses => {
                                return {
                                    id: theses.id,
                                    label: theses.studentFirstName + " " + theses.studentLastName,
                                    value: "ST " + theses.stNumber.toString()
                                };
                            })}>
                            <Button
                                onClick={handleDownloadArchive}
                                variant={"outlined"}
                                fullWidth
                            >
                                Download Archive
                            </Button>
                        </SelectedBox>
                    </div>
                </div>

                <ThesisDialog
                    handleClickOpen={() => console.log("open")}
                    handleClose={handleDialogClose}
                    thesis={clickedInfoTheses}
                    open={open}
                />
                <Table
                    isLoading={isFetching}
                    onRowClick={handleRowClick}
                    onColumnClick={handleHeaderColumnNameClick}
                    onCheckBoxClick={handleCheckBoxClick}
                    selectedTheses={selected}
                    data={theses.list}
                    columns={cols}
                />
            </PageContainer>
        </Container>
    )
}

export default SearchingPage;


const HeaderContainer = styled(Paper)`
  align-items: stretch;
  flex-direction: column;
  background-color: rgba(35, 71, 113, 0.06);
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
`

const Distance = styled.div<{ minWidth?: number, width?: number }>`
  min-width: ${(props) => props.minWidth}px;
  width: ${props => props.width ? props.width : 'auto'}px;
  margin-right: 20px;
`


const PageContainer = styled("div")`
  margin: 25px;
`
