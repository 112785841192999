import Step, {StepProps} from "./Step";
import {Checkbox, FormControlLabel, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import IThesisSpecs from "../../../dto/IThesisSpecs";
import {setWorkUpFormHint} from "../../../redux/action/ThesisUploadingFromActions";
import ThesisServiceAPI from "../../../api/thesisApi";
import confirmAdvice from "../advice/confirmAdvice";
import {useTranslation} from "react-i18next";

const {fetchConfirmation, fetchPublicationConfirmation} = ThesisServiceAPI;

class Confirmation extends Step {
    getForm = (): React.FC<StepProps> => ConfirmationFormStep;
}

export default Confirmation;

const ConfirmationFormStep = ({formik, setProgressLabel}: StepProps): JSX.Element => {
    const dispatch = useDispatch();
    const thesisSpecs: IThesisSpecs = formik.values;

    const confirmationForm: IThesisSpecs = formik.values;
    const [guarantee, setGuarantee] = useState<String>("");
    const [publicationGuarantee, setPublicationGuarantee] = useState<String>("");

    const {t, i18n} = useTranslation();

    useEffect(() => {
        dispatch(setWorkUpFormHint(confirmAdvice.get()))
    }, [dispatch])
    


    useEffect(() => {
        formik.setFieldValue("confirmed", false)
        formik.handleChange("confirmed")
        formik.setFieldValue("confirmation", false)
        fetchConfirmation(thesisSpecs.thesisId)
            .then((guarantee: String) => setGuarantee(guarantee))
            .catch((err: Error) => console.log(err.message))

            if(thesisSpecs.publicationConfirm || thesisSpecs.publicationConfirmCancel)
                formik.setFieldValue("isPublicationConfirmChoose", true);
        fetchPublicationConfirmation(thesisSpecs.thesisId)
        .then((publicationGuarantee: String) => setPublicationGuarantee(publicationGuarantee))
        .catch((err: Error) => console.log(err.message))
    }, [thesisSpecs.thesisId])

    useEffect(() => {
        ThesisServiceAPI.saveThesisSpecs(thesisSpecs.thesisId, thesisSpecs).catch(err => console.log(err))
    },  [thesisSpecs])


    const {errors} = formik;

    return (
        <div style={{
            display: "flex",
            textAlign: "left",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            maxWidth: 600,
        }}>
            
            <Typography align={"center"} variant={"h5"} color={"primary"}>
                {t("Confiramtion")}
            </Typography>
            <Paper elevation={0} style={{padding: 20,}}>

                <Typography variant={"body2"} style={{textIndent: 30, textAlign: 'justify', lineHeight: 1.8}}>
                    {guarantee}
                </Typography>

                <div style={{paddingTop: 10}}>
                    <FormControlLabel
                        control={<Checkbox checked={confirmationForm.confirmation}
                                           onChange={formik.handleChange}
                                           size={"small"}
                                           name="confirmation"
                                           color={"primary"}
                        />}
                        label={
                            <Typography style={{color: errors.confirmation ? 'red' : 'black'}} variant={"subtitle2"}>
                                {t("Confirmation accept")}
                            </Typography>
                        }/>
                </div>
            </Paper>
             <Typography align={"center"} variant={"h5"} color={"primary"}>
                {t("Confiramtion Publication")}
            </Typography>
            <Paper elevation={0} style={{padding: 20,}}>

                <Typography variant={"body2"} style={{textIndent: 30, textAlign: 'justify', lineHeight: 1.8}}>
                    {publicationGuarantee}
                </Typography>

                <div style={{paddingTop: 10}}>
                    <FormControlLabel
                        control={<Checkbox checked={confirmationForm.publicationConfirm}
                                           onChange={(e) => {
                                                formik.setFieldValue("publicationConfirm", e.target.checked);
                                                formik.handleChange(e);
                                               }
                                            }
                                           size={"small"}
                                           name="publicationConfirm"
                                           color={"primary"}
                        />}
                        label={
                            <Typography style={{color: 'black'}} variant={"subtitle2"}>
                                {t("Confirmation accept publication")}
                            </Typography>
                        }/>
                </div>
            </Paper>
                    
        </div>
    )
}
