import axios, {AxiosRequestConfig, AxiosResponse} from "axios";


const onReqSuccess = (config: AxiosRequestConfig) => ({...config})

const onReqError = (error: Error) => Promise.reject(error);


const onResSuccess = (response: AxiosResponse) => (response.data);

const onResError = (error: Error) => Promise.reject(error);


const AuthApiConfig = axios.create({baseURL: 'https://tsi-auth.tsi.lv', withCredentials: true});

const ThesisApiConfig = axios.create({baseURL: 'https://backends-api.tsi.lv/gradeworks'})

const graduationWorkPortalUrl = "https://backends-api.tsi.lv/gradeworks";

ThesisApiConfig.interceptors.request.use(onReqSuccess, onReqError);

ThesisApiConfig.interceptors.response.use(onResSuccess, onResError);


AuthApiConfig.interceptors.request.use(onReqSuccess, onReqError);

AuthApiConfig.interceptors.response.use(onResSuccess, onResError);


export {AuthApiConfig, ThesisApiConfig, graduationWorkPortalUrl};
