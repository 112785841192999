import {Trans} from "react-i18next";

const confirmAdvice = {
    get: () => {
        return (
            <>
                <p>
                    <Trans>
                        confirm advice
                    </Trans>
                </p>
            </>
        )
    },
};

export default confirmAdvice;
