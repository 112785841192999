import React, {PropsWithChildren, useState} from "react";
import styled from "styled-components";

type SectionProps = {
    id: string,
    label?: string,
    onHover?: Function
    hint?: React.ReactNode
}


const Section = ({children, ...props}: PropsWithChildren<SectionProps>) => {

    const {onHover, label, hint} = props;
    const [isBordered, setIsBordered] = useState(false);

    const onSectionHover = () => {
        setIsBordered(true)
        onHover && onHover(label, hint, true,);
    }

    const onSectionOut = () => {
        setIsBordered(false);
        onHover && onHover(label, hint, false);
    }

    return (
        <Container
            onMouseEnter={onSectionHover}
            onMouseLeave={onSectionOut}
            bordered={isBordered}
        >
            <Header>
                <Title>
                    {label}
                </Title>
            </Header>
            <Body>
                {children}
            </Body>
        </Container>
    )
}

export default Section;

const Title = styled.div`
  color: #4153b6;
  font-weight: bold;
  padding: 0 15px 15px 0;
`;

const Body = styled.div`
  text-align: left;
`;

const Container = styled.div<{ bordered?: boolean }>`
  padding: 10px 10px 15px;
  border-color: ${props => props.bordered ? "#234771" : "rgba(255, 0, 0, 0)"};
  border-left-style: solid;
  border-width: 2px;
  margin-bottom: 10px;
`;

const Header = styled.div`
  align-self: flex-start;
  text-align: left;
`;

