import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

interface TextAreaProps {
    rows?: number,
    cols?: number,
    id: string,
    name: string,
    onChange: any,
    value: string,
    limit?: number
    onLimit?: Function,
    error?: boolean
}

const TextArea: React.FC<TextAreaProps> = (props, children) => {

    const {limit = 0, value, error} = props;
    const {t, i18n} = useTranslation();

    const renderLimitLabel = () => {
        const color = value.length > limit ? "red" : "gray";
        return (
            <div style={{fontSize: 14, padding: 15, color, textAlign: "right"}}>
                {t('Symbols Limit')} {props.value.length}/{props.limit}
            </div>
        )
    }

    return (
        <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
            <Area
                id={props.id}
                rows={props.rows}
                cols={props.cols}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                borderColor={error && "red"}
            />
            {limit > 0 && renderLimitLabel()}
        </div>
    )
}

export default TextArea;

const Area = styled('textarea')<any>`
  border-color: ${props => props.borderColor || "gainsboro"};
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  padding: 20px;
  resize: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  text-align: justify;
  font-family: "Times New Roman", Times, serif;
`
