import {AnySchema} from "yup";
import React from "react";

export interface StepProps {
    formik: any,
    setProgressLabel: Function,
}

abstract class Step {

    private readonly _id: string;

    private readonly _stepLabel: string;
    private readonly _formValidationSchema: AnySchema | undefined;

    public constructor(id: string, label: string, schema: AnySchema | undefined) {
        this._id = id;
        this._stepLabel = label;
        this._formValidationSchema = schema;
    }

    get id(): string {
        return this._id;
    }

    get stepLabel(): string {
        return this._stepLabel;
    }

    get formValidationSchema(): AnySchema | undefined {
        return this._formValidationSchema;
    }

    abstract getForm(): React.FC<StepProps>;


}

export default Step
