import {AuthActionTypes, AUTHENTICATION_SUCCESS, LOGOUT} from "../../type";

export interface ProfileReducer {
    userId: number,
    roles: Array<string>,
    fullName: string,
    accessToken: string
}

const initialState: ProfileReducer = {
    userId: -1,
    roles: [],
    fullName: "",
    accessToken: ""
};

export const profileReducer = (state: ProfileReducer = initialState, action: AuthActionTypes): ProfileReducer => {
    switch (action.type) {
        case AUTHENTICATION_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case LOGOUT: {
            return {
                ...initialState,
            }
        }
        default:
            return state;
    }
};
