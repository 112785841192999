import {Button, Card, CardActionArea, CardActions, CardContent, Divider, Typography} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import IThesisCard from "../dto/IThesisCard";
import {useTranslation} from "react-i18next";

interface ThesisCardProps {
    key?: string | number;
    thesis: IThesisCard,
    onUploadButtonClick: any,
}

const ThesisCard = ({thesis, onUploadButtonClick}: ThesisCardProps) => {

    const {t, i18n} = useTranslation();

    return (
        <Card elevation={0} style={{textAlign: 'left', width: "100%"}}>
            <CardContent style={{padding: 0}}>
                <Typography color={"primary"} gutterBottom variant="h5" component="h2">
                    {i18n.language === "lv" ? thesis.studyProgramLv : thesis.studyProgramEn}
                </Typography>
                <table>
                    <tbody>
                    <tr key={"work-title"}>
                        <Label>{t('Thesis Title')}:</Label>
                        <Value>{i18n.language === "lv" ? thesis.titleLv : thesis.titleEn}</Value>
                    </tr>
                    <tr key={"work-supervisor"}>
                        <Label>{t('Supervisor')}:</Label>
                        <Value>{thesis.supervisorNameLv}</Value>
                    </tr>
                    <tr key={"work-student"}>
                        <Label>{t('Student')}:</Label>
                        <Value>{thesis.studentNameLv}</Value>
                    </tr>
                    <tr key={"work-state"}>
                        <Label>{t('Status')}:</Label>
                        <Value>{thesis.final ? t('Uploaded') : t('Waiting for uploading')}</Value>
                    </tr>
                    </tbody>
                </table>
            </CardContent>

            <Divider variant={"fullWidth"} style={{marginTop: 15, marginBottom: 20}}/>

            <CardActions style={{padding: 0}}>
                <Button
                    color={thesis.final ? "default" : "primary"}
                    onClick={() => onUploadButtonClick(thesis)}
                    variant={"contained"}
                    size="small"
                >
                    {thesis.final ? t('Download Thesis') : t('Upload Thesis')}
                </Button>
            </CardActions>
        </Card>
    )
}
/*


 */
export default ThesisCard;

const Value = styled.td`
  padding: 5px;
  line-height: 1.5;
  color: darkslategray;
`;

const Label = styled.td`
  color: #234771;
  font-weight: bold;
  padding: 5px;
  min-width: 150px;
`;

