import {Trans} from "react-i18next";

const abstractAdvice = {
    get: () => {
        return (
            <>
                <p>
                    <Trans>
                        abstract advice
                    </Trans>
                </p>
            </>
        )
    },
};

export default abstractAdvice;

