import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux";
import {Box, Button, ButtonGroup, Typography} from "@material-ui/core";


import SchoolIcon from '@material-ui/icons/School';
import SearchIcon from '@material-ui/icons/Search';
import Role from "../component/Role";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {logout} from "../redux/action/AuthActions";
import {AuthApi} from "../api/authApi";


const RoleSelectPage = (props: any) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const lng = (lng: string) => i18n.changeLanguage(lng).then(() => console.log("success"))

    const studentText: String = "If you are going to upload a new thesis paper or download a copy of an already uploaded paper, you should be logged in as a student"
    const viewerText: String = "If you need to find a particular thesis paper in the database, you can use the viewer role"

    const information = {
        EMPLOYEE: viewerText,
        STUDENT: studentText,
    }

    const history = useHistory();

    const userRoles = useSelector((state: RootState) => state.profile.roles)
    const [role, setRole] = useState(userRoles[0]);

    const [informText, setInformText] = useState<String>("Select")

    const onRoleSelect = (id: string) => {
        setRole(id)
    }

    const onSelectButtonClick = () => {
        const path = role === "EMPLOYEE" ? "/search" : "/student/works";
        history.push(path);
    }

    useEffect(() => {
        // @ts-ignore
        const text: String = information[role];
        setInformText(text);
    }, [role])

    return (
        <Container>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <ButtonGroup variant="text">
                    <Button size={'small'} onClick={() => lng("ru")}>RU</Button>
                    <Button size={'small'} onClick={() => lng("lv")}>LV</Button>
                    <Button size={'small'} onClick={() => lng("en")}>EN</Button>
                </ButtonGroup>
                <div>
                    <Button onClick={() => dispatch(logout())} size={"small"} color={"primary"}>
                        Logout
                    </Button>
                </div>
            </div>
            <Box mb={4} mt={4}>
                <Typography
                    style={{fontWeight: 'bold'}}
                    className={"text-tsi"}
                    variant={"h5"}
                    align={"center"}>
                    {t('Select role')}
                </Typography>
            </Box>
            <Box mb={4}>
                <RoleList>
                    {_.includes(userRoles, "STUDENT") &&
                    <Role
                        id={"STUDENT"}
                        label={t('Student')}
                        onClick={onRoleSelect}
                        isSelected={role === "STUDENT"}
                        Icon={SchoolIcon}
                    />
                    }
                    {_.includes(userRoles, "EMPLOYEE") &&
                    <Role
                        id={"EMPLOYEE"}
                        label={t('Viewer')}
                        onClick={onRoleSelect}
                        isSelected={role === "EMPLOYEE"}
                        Icon={SearchIcon}
                    />
                    }

                </RoleList>
            </Box>
            <Box mb={4}>
                <Typography align={"center"} color={"textSecondary"}>
                    {t('role select advice')}
                </Typography>
            </Box>
            {role && <Button
                onClick={onSelectButtonClick}
                variant="contained"
                color="primary"
            >
                {t('Select')}
            </Button>}
        </Container>
    )
}

export default RoleSelectPage;

const RoleList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const Container = styled.div`
  position: relative;
  padding: 25px 30px 30px;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;
  display: flex;
  width: 420px;
  background-color: #FFF;
  border-radius: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

