import {
    REFRESH_TOKEN_EXPIRED,
    ACCESS_TOKEN_REFRESHED,
    AuthActionTypes,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_PROCESS,
    AUTHENTICATION_SUCCESS,
    LOGOUT
} from "../../type";

export interface AuthReducer {
    isRefreshTokenExpired: boolean
    isLoading: boolean,
    token: string,
    error: string,
}

const initialState: AuthReducer = {
    isRefreshTokenExpired: false,
    isLoading: false,
    token: "",
    error: "",
};

export const authReducer = (state: AuthReducer = initialState, action: AuthActionTypes): AuthReducer => {
    switch (action.type) {
        case AUTHENTICATION_PROCESS: {
            return {
                ...state,
                isLoading: true,
                token: "",
                error: '',
            };
        }
        case AUTHENTICATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                token: action.payload.accessToken,
                isRefreshTokenExpired: false,
                error: '',
            };
        }
        case AUTHENTICATION_FAIL: {
            return {
                token: "",
                isLoading: false,
                error: action.payload,
                isRefreshTokenExpired: true,
            };
        }
        case ACCESS_TOKEN_REFRESHED: {
            return {
                ...state,
                isRefreshTokenExpired: false,
                token: action.payload,
            }
        }
        case REFRESH_TOKEN_EXPIRED: {
            return {
                ...state,
                isRefreshTokenExpired: true,
            }
        }
        case LOGOUT: {
            return {
                ...initialState,
                isRefreshTokenExpired: true,
            }
        }
        default:
            return state;
    }
};
