import {CloudUpload} from "@material-ui/icons";
import {useDropzone} from 'react-dropzone';
import styled from "styled-components";


interface DropZoneProps {
    dropLabel?: any,
    maxFiles?: number
    accept?: string
    onFileRejected?: any,
    onFileAccept?: any,
}

const DropZone = (props: DropZoneProps) => {

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDropAccepted: props.onFileAccept,
        onDropRejected: props.onFileRejected,
        accept: props.accept,
        maxFiles: props.maxFiles
    });

    return (
        <Container>
            <DropZoneContainer {...getRootProps({className: 'dropzone'})} >
                <input {...getInputProps()} />
                <CloudUpload fontSize={"large"}/>
                <div>
                    {props.dropLabel}
                </div>
            </DropZoneContainer>
        </Container>
    );
}

export default DropZone

const Container = styled.div`
  padding-bottom: 10px;
`

const DropZoneContainer = styled.div`
  background-color: #fafafa;
  border-style: dashed;
  border-color: #e0dede;
  border-radius: 5px;
  border-width: 2px;
  text-align: center;
  padding: 15px;
`
