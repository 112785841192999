import React, { useEffect, useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import IThesis from "../dto/search/IThesis";
import DepartmentEnum from "../model/DepartmentEnum";
import {fetchFinalDocument, fetchOriginalDocument, getLinkToFinalWork, checkFinalDocumentExistence, checkOriginalDocumentExistence} from "../api/thesisFilesApi";
import styled from "@emotion/styled";
import {Typography} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import {useTranslation} from "react-i18next";


type ThesisDialogProps = {
    open: any,
    handleClose: any,
    handleClickOpen: any,
    thesis: IThesis | undefined,
}

const ThesisDialog: React.FC<ThesisDialogProps> = (props) => {


    const thesis = props.thesis;
    const {t, i18n} = useTranslation();

    
     const [thesisFinalExistence, setThesisFinalExistance] = useState<boolean>(false)
     const [thesisOriginalExistence, setThesisOriginalExistance] = useState<boolean>(false)

     useEffect(() => {
        if(props.thesis) {
            checkFinalDocumentExistence(props.thesis)
            .then(result => setThesisFinalExistance(result))
            .catch(console.log)
            checkOriginalDocumentExistence(props.thesis)
            .then(result => setThesisOriginalExistance(result))
            .catch(console.log)
        }
     }, [props.thesis])
    // @ts-ignore
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{lineHeight: 2}}>
                    <table style={{width: '100%'}}>
                        <tr>
                            <td><b>Latvian name:</b></td>
                            <td><Typography color={"primary"}><b>{props.thesis?.thesisNameLv}</b></Typography></td>
                        </tr>
                        <tr>
                            <td><b>English name:</b></td>
                            <td><Typography color={"primary"}><b>{props.thesis?.thesisNameEn}</b></Typography></td>
                        </tr>
                        <tr>
                            <td><b>Student number:</b></td>
                            <td>{props.thesis?.stNumber}</td>
                        </tr>
                        <tr>
                            <td><b>Student full name:</b></td>
                            <td>{props.thesis?.studentFirstName} {props.thesis?.studentLastName}</td>
                        </tr>
                        <tr>
                            <td><b>Supervisor full name:</b></td>
                            <td>{props.thesis?.supervisorFullName}</td>
                        </tr>
                        <tr>
                            <td><b>Year:</b></td>
                            <td>{props.thesis?.graduatedYear}</td>
                        </tr>
                        <tr>
                            <td><b>Uploaded:</b></td>
                            <td>{props.thesis?.uploaded}</td>
                        </tr>
                        <tr>
                            <td><b>Path:</b></td>
                            <td>{props.thesis?.department && DepartmentEnum.get(props.thesis?.department)}</td>
                        </tr>
                        <tr>
                            <td><b>Link to final work:</b></td>
                            <td>
                                <StyledButton style={{
                                    border: "none",
                                    color: 'white',
                                    padding: 5,
                                    borderRadius: 5,
                                    backgroundColor: thesisOriginalExistence ? "#2196f3" : "#00000042",
                                }}
                                              onClick={() => {
                                                  // @ts-ignore
                                                  const link = getLinkToFinalWork(props.thesis);
                                                  navigator.clipboard.writeText(link).then(() => console.log("ok"))
                                              }}
                                              disabled = {!thesisOriginalExistence}
                                              >
                                    Copy link to clipboard
                                </StyledButton>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>

                                <Typography color="error" style={{
                                    paddingLeft: 10,
                                    backgroundColor: "#ffb3b3",
                                    borderRadius: "8px",
                                    width: "84.5%",
                                    visibility: 
                                    !thesisOriginalExistence || !thesisFinalExistence ?
                                        "visible" 
                                        : 
                                        "hidden"
                                }}>
                                    {t('required file not found')}
                                </Typography>   
                            </td>
                        </tr>
                    </table>

                    {/* <Typography color="error" style={{
                        paddingLeft: 200,
                        // backgroundColor: "red",
                        visibility: 
                            !thesisOriginalExistence || !thesisFinalExistence ?
                            "visible" 
                            : 
                            "hidden"
                    }}>
                        {t('required file not found')}
                    </Typography> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        const id: number = props.thesis?.id ? props.thesis.id : 0
                        // @ts-ignore
                        if(props.thesis)
                            fetchFinalDocument(props.thesis).catch((e) => console.log(e))
                    }} color="primary"
                    disabled = {!thesisFinalExistence}
                    >
                        Final version
                    </Button>
                    <Button onClick={() => {
                        const id: number = props.thesis?.id ? props.thesis.id : 0
                        // @ts-ignore
                        fetchOriginalDocument(props.thesis).catch(e => console.log(e))
                    }} color="primary" 
                    disabled = {!thesisOriginalExistence}
                    autoFocus>
                        Plagiarism version
                    </Button>

                </DialogActions>
            </Dialog>
        </>
    )

}

export default ThesisDialog;


const StyledButton = styled("button")`
&:active {
  background: #e5e5e5;
  -webkit-box-shadow: inset 0px 0px 15px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 15px #c1c1c1;
          box-shadow: inset 0px 0px 15px #c1c1c1;
   outline: none;
}
`