type CheckBoxProps = {
    label: string;
    id: string;
    name: string;
    disable?: boolean;
    value: any,
    onChange: any,
    checked: boolean,
}

const Radio = (props: CheckBoxProps) => {

    const {id, value, checked, onChange, label, name, disable = false} = props;

    return (
        <div style={{display: 'flex', alignItems: 'stretch'}}>
            <div style={{marginRight: 2}}>
                <input
                    key={"input-" + label}
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    disabled={disable}
                    type="radio"
                    name={name}
                    id={id}
                />
            </div>
            <label
                key={"label-" + label}
                style={{fontSize: 15}}
                aria-describedby="label"
                htmlFor={id}
            >
                {label}
            </label>
        </div>
    )
}

export default Radio;
