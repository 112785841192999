import IThesisCard from "../dto/IThesisCard";
import IThesisSpecs from "../dto/IThesisSpecs";
import {ThesisApiConfig} from "./config";

const {get, post, put} = ThesisApiConfig;

export default {
    fetchStudentTheses: (studentId: number): Promise<IThesisCard[]> => get(`/students/${studentId}/theses`),
    fetchThesisSpecs: (thesisId: number): Promise<IThesisSpecs> => get(`/thesis/${thesisId}/specs`),
    assembleFinalDocument: (thesisId: number): Promise<void> => post(`/thesis/${thesisId}/files/final`),
    fetchConfirmation: (thesisId: number,): Promise<String> => get(`/thesis/${thesisId}/confirmation`),
    fetchPublicationConfirmation: (thesisId: number,): Promise<String> => get(`/thesis/${thesisId}/publicationConfirmation`),
    saveThesisSpecs: (thesisId: number, specs: IThesisSpecs) => put(
        `/thesis/${thesisId}/specs`, specs,
        {headers: {'Content-Type': 'application/json'}}
    ),
}
